<template>
  <div>
    <div class="flex">
      <div class="container mx-auto bg-white w-1/4 p-4">
        <template v-for="(stage, index3) in stage_list" :key="index3">
          <div class="font-bold">{{ stage.title }}</div>
          <button
            class="mx-2 my-1 p-1.5 font-extralight text-sm ject-but"
            v-for="(subject, s_index) in stage.StageSubject"
            :key="s_index"
          >
            {{ subject.title }}
          </button>
        </template>
      </div>
      <div class="container bg-green w-2/4 mx-3">
        <el-carousel>
          <el-carousel-item
            height="100%"
            v-for="(slider, index) in siteInfo.slider_list"
            :key="index"
          >
            <h3 class="small">
              <a href=""><img :src="slider.image" alt /></a>
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="container mx-auto bg-white w-1/4">
        <div class="font-light text-sm mt-7 ml-4" v-if="siteInfo.site_data">
          <span>测试总量（道）：</span>
          <span class="font-medium text-3xl text-orange">{{
            siteInfo.site_data[0].total
          }}</span>
        </div>
        <div class="font-light text-sm mt-7 ml-4" v-if="siteInfo.site_data">
          <span>测试总量（道）：</span>
          <span class="font-medium text-3xl text-orange">{{
            siteInfo.site_data[1].total
          }}</span>
        </div>
        <button class="start-test">开始组卷</button>
        <div><button class="good-test">精品试卷</button></div>
        <div class="flex-x-center mt-4 mb-4">
          <div class="flex test-icon-three">
            <span class="w-1/3">
              <span class="iconfont icon-chaxun three-icon"></span>
              <div class="font-light text-sm">有错纠错</div>
            </span>
            <span class="w-1/3 mx-3">
              <span class="iconfont icon-fenxiang2 three-icon2"></span>
              <div class="font-light text-sm -ml-3">有奖组卷分享</div>
            </span>
            <span class="w-1/3">
              <span class="iconfont icon-tianxieziliao three-icon3"></span>
              <div class="font-light text-sm">试题解析</div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex my-5">
      <div class="container bg-white w-1/2 p-4">
        <div class="block my-5 bg-white mx-auto py-5">
          <div class="container px-3 text-2xl border-l-4 border-green-400">
            最新试卷
          </div>
          <div class="flex">
            <div class="container">
              <el-menu
                :default-active="activeIndex"
                class="el-menu-demo w-full"
                mode="horizontal"
                active-text-color="green"
              >
                <el-menu-item index="1">同步专区</el-menu-item>
                <el-menu-item index="2">备考专区</el-menu-item>
                <el-menu-item index="3">中考专区</el-menu-item>
              </el-menu>
            </div>
            <div class="flex-end p-2 min-w-20 text-gray flex-x-y-center">
              更多 >
            </div>
          </div>
        </div>
        <div class="p-5">
          <li
            class="my-2 font-light"
            v-for="(Exam, test) in ExamList"
            :key="test"
          >
            {{ Exam.title }}

            <span class="ml-8 font-thin">
              {{ Exam.create_time }}
            </span>
          </li>
        </div>
      </div>
      <div class="container bg-white w-1/2 p-4">
        <div class="block my-5 bg-white mx-auto py-5">
          <div class="container px-3 text-2xl border-l-4 border-green-400">
            最新专题
          </div>
          <div class="flex">
            <div class="container">
              <el-menu
                :default-active="activeIndex"
                class="el-menu-demo w-full"
                mode="horizontal"
              >
                <el-menu-item index="1">同步专题</el-menu-item>
                <el-menu-item index="2">备考专题</el-menu-item>
                <el-menu-item index="3">升学专题</el-menu-item>
              </el-menu>
            </div>
            <div class="flex-end p-2 min-w-20 text-gray flex-x-y-center">
              更多 >
            </div>
          </div>
        </div>
        <div class="p-5">
          <li class="my-2 font-light" v-for="(item, test) in tests" :key="test">
            {{ item.title }}

            <span
              class="ml-8 font-thin"
              v-for="(item, time) in tests"
              :key="time"
            >
              {{ item.time }}
            </span>
          </li>
        </div>
      </div>
    </div>
    <div class="flex m-2 container mx-auto bg-white">
      <div
        v-for="(item, index) in siteInfo.site_data"
        :key="index"
        class="container mx-2 bg-white w-1/5 p-4"
      >
        <div class="flex-x-y-center">
          <div class="iconfont icon-chaxun question-bank-icon"></div>
        </div>
        <div class="flex-x-y-center">
          <div class="text-2xl font-medium">{{ item.title }}</div>
        </div>
        <div class="flex-x-y-center">
          <div class="mt-4 font-thin">{{ item.desc }}</div>
        </div>
        <div class="flex-x-y-center">
          <div class="text-4xl font-medium mt-4 text-orange">
            {{ item.total }}
          </div>
        </div>
        <div class="flex-x-y-center">
          <div class="font-thin mt-4">{{ item.today }}</div>
        </div>
      </div>
    </div>
    <div class="position">
      <div class="position-tip text-sm flex-x-y-center">
        <div>
          <div class="tip-bottom text-xs">
            <div class="flex-x-y-center">QQ</div>
            客服
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-women"></div>
            我们
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-xiugaioryijian"></div>
            建议
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-fanhuidingbu"></div>
            顶部
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      siteInfo: "",
      tests: [
        {
          time: "01-17",
        },
        {
          title: "湘教版初中数学七年级下册1.3二元一次方程组的应用同步练习",
        },
        {
          title: "湘教版初中数学七年级下册1.3二元一次方程组的应用同步练习2",
        },
        {
          title: "湘教版初中数学七年级下册1.3二元一次方程组的应用同步练习3",
        },
      ],
      list_img: [
        {
          url: "//tikupic.21cnjy.com/2022/01/15/55/4e/554e1f0de619954b8c9f6c4dfe128d4a.jpg",
        },
        {
          url: "//tikupic.21cnjy.com/2021/12/22/4e/d7/4ed743f47f9b481cc7e14c441550d796.png",
        },
        {
          url: "//tikupic.21cnjy.com/2021/12/22/82/3c/823c04e9e830d0b679f86933a30cfdbc.png",
        },
      ],
      stage_list: {},
      ExamList: {},
    };
  },
  created() {
    this.getSiteInfo();
    this.getStageList();
    this.getExamList();
  },
  methods: {
    async getSiteInfo() {
      const data = await this.$store.dispatch("site/getSiteInfo");
      console.log(data);
      this.siteInfo = data.data;
    },
    async getStageList() {
      const data = await this.$store.dispatch("tiku/getStageList");
      console.log("stage", data);
      this.stage_list = data.data;
    },
    async getExamList() {
      const data = await this.$store.dispatch("tiku/getExamList");
      console.log("ExamList", data);
      this.ExamList = data.data;
    },
  },
};
</script>

<style scoped>
.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
  font-size: 10px;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 35px;
  background-color: rgb(42, 41, 41);
  color: white;
  height: 100%;
  padding: 5px;
}

.question-bank-icon {
  font-size: 80px;
  color: rgb(236, 179, 238);
}

.circle-test {
  width: 5px;
  height: 5px;
  background-color: rgb(170, 163, 163);
  border-radius: 100%;
}

.three-icon {
  font-size: 40px;
  color: rgb(238, 170, 124);
}

.three-icon2 {
  font-size: 40px;
  color: rgb(71, 153, 207);
}

.three-icon3 {
  font-size: 40px;
  color: #11ba67;
}

.test-icon-three {
  width: 90%;
  margin-left: 5%;
}

.good-test {
  width: 90%;
  background-color: white;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: #11ba67;
  font-size: 16px;
  font-weight: 330;
  margin-top: 10px;
  border: #11ba67 1px solid;
}

.start-test {
  width: 90%;
  background-color: #11ba67;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 330;
  margin-top: 20px;
}

.ject-but:hover {
  transform: scale(1.1);
  color: rgb(248, 244, 244);
  background-color: #11ba67;
  border-radius: 2px;
}

.ject-but {
  border: none;
}
</style>
