<template>
	<div>
		<header class="bg-white shadow">
			<div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
				<h1 class="text-3xl font-bold text-gray-900">
					包月服务
				</h1>
			</div>
		</header>
		<main>
			<div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<!-- Replace with your content -->
				<div class="px-4 py-6 sm:px-0">
					asdf
					</div>
			</div>
		</main>
	</div>
</template>

<script>
	
	export default {
		name: "Month",
		data() {
			return {
				isNavOpen: false,

				billPlan: 'monthly',

			};
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
		},
		setup() {
			return {
				
			}
		},
	};
</script>

<style scoped>
	.tip-bottom:hover {
		cursor: pointer;
		transform: scale(1.1);
	}

	.tip-bottom {
		border: none;
	}

	.position-tip2 {
		position: fixed;
		right: 0px;
		top: 30px;
		width: 50px;
		background-color: black;
		color: white;
		height: 100%;
	}

	.position-tip {
		position: fixed;
		right: 0px;
		top: 0px;
		width: 60px;
		background-color: black;
		color: white;
		height: 100%;
	}

	.question-bank-icon {
		font-size: 80px;
		color: rgb(236, 179, 238);
	}

	.circle-test {
		width: 5px;
		height: 5px;
		background-color: rgb(170, 163, 163);
		border-radius: 100%;
	}

	.three-icon {
		font-size: 40px;
		color: rgb(238, 170, 124);
	}

	.three-icon2 {
		font-size: 40px;
		color: rgb(71, 153, 207);
	}

	.three-icon3 {
		font-size: 40px;
		color: #11ba67;
	}

	.test-icon-three {
		width: 90%;
		margin-left: 5%;
	}

	.good-test {
		width: 90%;
		background-color: white;
		margin-left: 5%;
		height: 40px;
		border-radius: 4px;
		color: #11ba67;
		font-size: 16px;
		font-weight: 330;
		margin-top: 10px;
		border: #11ba67 1px solid;
	}

	.start-test {
		width: 90%;
		background-color: #11ba67;
		margin-left: 5%;
		height: 40px;
		border-radius: 4px;
		color: white;
		font-size: 16px;
		font-weight: 330;
		margin-top: 20px;
	}

	.ject-but:hover {
		transform: scale(1.1);
		color: rgb(248, 244, 244);
		background-color: #11ba67;
		border-radius: 2px;
	}

	.ject-but {
		border: none;
	}
</style>
