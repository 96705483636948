<template>
  <div
    class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div class="min-h-80">
        <div class="text-2xl md:text-3xl text-center">注册</div>
        <el-form
          ref="formRefs"
          :model="formModel"
          :rules="formRules"
          class="mt-8"
        >
          <el-form-item prop="mobile">
            <el-input v-model="formModel.mobile" placeholder="请输入手机号">
              <template #prefix>
                <via-svg-icon name="user" class="text-2xl"></via-svg-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="captcha">
            <el-col :span="12">
              <el-input
                v-model="formModel.captcha"
                placeholder="请输入图形验证码"
              >
                <template #prefix>
                  <via-svg-icon name="code" class="text-2xl"></via-svg-icon>
                </template>
              </el-input>
            </el-col>
            <el-col :span="12" class="w-full">
              <img
                @click="getCaptcha()"
                class="codeImg"
                :src="formModel.url"
                alt=""
                height="40"
              />
            </el-col>
          </el-form-item>
          <el-form-item prop="code">
            <el-col :span="12">
              <el-input v-model="formModel.code" placeholder="请输入短信验证码">
                <template #prefix>
                  <via-svg-icon name="code" class="text-2xl"></via-svg-icon>
                </template>
              </el-input>
            </el-col>
            <el-col :span="12" class="w-full">
              <el-button
                type="primary"
                v-show="wait == 0"
                class="w-full"
                @click="sendSmsCode()"
              >
                {{ content }}
              </el-button>
              <el-button
                v-show="wait > 0"
                class="w-full"
                disabled
                style="font-size: 8px"
              >
                {{ content }}
              </el-button>
            </el-col>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="formModel.password"
              placeholder="请输入登录密码"
              type="password"
            >
              <template #prefix>
                <via-svg-icon name="password" class="text-xl"></via-svg-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="repassword">
            <el-input
              v-model="formModel.repassword"
              placeholder="请再次输入登录密码"
              type="password"
            >
              <template #prefix>
                <via-svg-icon name="password" class="text-xl"></via-svg-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="flex items-center justify-between">
              <el-checkbox v-model="formModel.agree" size="medium">
                阅读并同意用户协议
              </el-checkbox>
              <a
                href="#/market/login"
                class="text-gray-400 hover:text-gray-900 text-sm"
              >
                登录已有账号
              </a>
            </div>
            <el-button
              type="primary"
              size="medium"
              class="w-full"
              @click="userRegister"
            >
              注册
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LockClosedIcon } from "@heroicons/vue/solid";
const navigation = [];

export default {
  name: "Login",
  components: {
    LockClosedIcon,
  },
  setup() {
    return {
      navigation,
    };
  },
  computed: {
    ...mapGetters({
      demoData: "demoData",
      userInfo: "userInfo",
    }),
  },
  mounted() {
    if (this.userInfo.id > 0) {
      this.$router.replace({
        name: "api",
      });
    }
    this.getCaptcha();
    this.daojishi();
  },
  data() {
    return {
      wait: localStorage.getItem("wait") ? localStorage.getItem("wait") : 0,
      content: "获取验证码",
      canClick: true,
      isSelected: false,
      formModel: {
        mobile: "",
        password: "",
        repassword: "",
        agree: true,
        code: "",
        src: "",
        captcha_key: "",
        captcha: "",
      },
      formRules: {
        mobile: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        repassword: [
          {
            required: true,
            message: "重复密码不能为空",
            trigger: "blur",
          },
        ],
        aress: [
          {
            required: true,
            message: "请阅读并同意用户协议",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    daojishi() {
      if (this.wait == 0) return;
      this.canClick = false;
      this.content = this.wait + "秒后重新发送"; //这里解决60秒不见了的问题
      let clock = window.setInterval(() => {
        this.wait--;
        localStorage.setItem("wait", this.wait);
        this.content = this.wait + "秒后重新发送";
        if (this.wait <= 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(clock);
          this.content = "获取验证码";
          localStorage.setItem("wait", 0);
          this.canClick = true;
        }
      }, 1000);
    },
    async sendSmsCode() {
      if (this.timer > 0) {
        this.$message({
          message: this.timer + "秒后重新发送",
          type: "error",
        });
      }

      const res = await this.$store.dispatch("sms/sendSmsCode", this.formModel);

      console.log("check:", res);
      if (res.code == 1) {
        localStorage.setItem("wait", 45);
        this.wait = 45;
        this.canClick = false;
        this.daojishi();
        this.$message({
          message: res.message,
          type: "succcess",
        });
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
        this.getCaptcha();
      }
    },
    async getCaptcha() {
      const res = await this.$store.dispatch("sms/getCaptcha");
      console.log("check:", res);
      if (res.code == 1) {
        this.formModel.url = res.data.captcha;
        this.formModel.captcha_key = res.data.key;
      }
    },
    async userRegister() {
      const isValid = await this.$refs.formRefs.validate();
      if (isValid) {
        //调用登录接口
        const res = await this.$store.dispatch(
          "user/userRegister",
          this.formModel
        );

        if (res.code == 1) {
          this.$message({
            message: res.message,
            type: "succcess",
          });
          this.$router.replace({
            name: "login",
          });
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      }
    },
    toLoigin() {
      alert("去登陆");
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.codeImg {
  width: 100%;
  height: 40px;
}

.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 60px;
  background-color: black;
  color: white;
  height: 100%;
}

.question-bank-icon {
  font-size: 80px;
  color: rgb(236, 179, 238);
}

.circle-test {
  width: 5px;
  height: 5px;
  background-color: rgb(170, 163, 163);
  border-radius: 100%;
}

.three-icon {
  font-size: 40px;
  color: rgb(238, 170, 124);
}

.three-icon2 {
  font-size: 40px;
  color: rgb(71, 153, 207);
}

.three-icon3 {
  font-size: 40px;
  color: #11ba67;
}

.test-icon-three {
  width: 90%;
  margin-left: 5%;
}

.good-test {
  width: 90%;
  background-color: white;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: #11ba67;
  font-size: 16px;
  font-weight: 330;
  margin-top: 10px;
  border: #11ba67 1px solid;
}

.start-test {
  width: 90%;
  background-color: #11ba67;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 330;
  margin-top: 20px;
}

.ject-but:hover {
  transform: scale(1.1);
  color: rgb(248, 244, 244);
  background-color: #11ba67;
  border-radius: 2px;
}

.ject-but {
  border: none;
}
</style>
