import {
    getStorages,
    setStorages,
    removeStorages
} from "@/plugins/storages";
import request from "@/request";
import {
    toast
} from "@/plugins/modal";
export default {
    namespaced: true,
    state: () => ({
        userInfo: getStorages("userInfo") ? getStorages("userInfo") : {},
        token: getStorages("token")
    }),
    getters: {},
    mutations: {
        setUserInfo(state, data) {
            state.userInfo = {
                ...data
            };
            setStorages("userInfo", data);
        },
        removeToken(state) {
            state.token = "";
            removeStorages("token");
        },
        setToken(state, token) {
            state.token = token;
            setStorages("token", token);
        }
    },
    actions: {
        //登录接口
        async userLogin({
            commit,
            state
        }, data) {
            const result = await request.userLogin({}, {
                data
            });
            console.log('userlogin', result.message)
            if (result.code == 1) {
                commit("setToken", result.data.userinfo.token);
                commit("setUserInfo", result.data.userinfo);

                return state.userInfo;
                return true;
            } else {
                toast(result.message, {
                    type: "warning"
                });
            }
            return false;
        },
        userLogout({
            commit,
            state
        }, data) {
            commit("setToken", {});
            commit('setUserInfo', {})
        },

        //注册接口
        async userRegister({
            commit,
            state
        }, data) {
            const result = await request.userRegister({}, {
                data
            });
            return result;
        },
        //重置密码接口
        async userPassword({

        }, data) {
            const result = await request.userPassword({}, {
                data
            });
            return result;
        },

        //获取用户详情
        async getUserInfo({
            commit,
            state
        }, {
            params = {},
            options = {}
        } = {}) {
            const result = await request.getUserInfo(params, options);
            if (result.code == 1) {
                const info = result.data;

                commit("setUserInfo", info);

                return state.userInfo;
            } else {
                toast(result.message, {
                    type: "warning"
                });
            }

        },
        //修改会员资料接口
        async changeUserInfo({

        }, data) {
            const result = await request.changeUserInfo({}, {
                data
            });
            return result;
        },

        //修改会员绑定手机号
        async bindingMobilePhone({

        }, data) {
            const result = await request.bindingMobilePhone({}, {
                data
            });
            return result;
        },
    },

    //刷新页面 他写好方法
    //this.$store.dispatch("user/getUserInfo")

};