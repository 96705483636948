<template>
	<div>
	 <div class="relative bg-white overflow-hidden">
	    <div class="max-w-7xl mx-auto">
	      <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
	        <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
	          <polygon points="50,0 100,0 50,100 0,100" />
	        </svg>
	
	        <Popover>
	          <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
	            <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
	              <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
	                <div class="flex items-center justify-between w-full md:w-auto">
	                  
	                  <div class="-mr-2 flex items-center md:hidden">
	                    <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
	                      <span class="sr-only">Open main menu</span>
	                      <MenuIcon class="h-6 w-6" aria-hidden="true" />
	                    </PopoverButton>
	                  </div>
	                </div>
	              </div>
	             
	            </nav>
	          </div>
	
	          
	        </Popover>
	
	        <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-20">
	          <div class="sm:text-center lg:text-left">
	            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
	              <span class="block xl:inline">极速接入第三方服务</span>
	            
	            </h1>
	            <p class="mt-5 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
					云豹框架企业服务平台与市面众多企业服务平台合作，集成全行业数十家服务商，上百个各类api接口及服务， 
					供企业统一开通和使用，极大的降低企业的第三方服务使用及维护成本
					</p>
	            <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
	              <div class="rounded-md shadow">
	                <a href="#/market/api" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"> 立即选购 </a>
	              </div>
	            
	            </div>
	          </div>
	        </main>
	      </div>
	    </div>
	    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
	      <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="" />
	    </div>
	  </div>
	</div>
</template>

<script>
	import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
	import { MenuIcon, XIcon } from '@heroicons/vue/outline'
	
	const navigation = [
	 
	  
	]
	
	export default {
		name: "Tuijian",
		components: {
		  Popover,
		  PopoverButton,
		  PopoverPanel,
		  MenuIcon,
		  XIcon,
		},
		setup() {
		  return {
		    navigation,
		  }
		},
		

		data() {
			return {
				
			};
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
		},
		
	};
</script>

<style scoped>
	.tip-bottom:hover {
		cursor: pointer;
		transform: scale(1.1);
	}

	.tip-bottom {
		border: none;
	}

	.position-tip2 {
		position: fixed;
		right: 0px;
		top: 30px;
		width: 50px;
		background-color: black;
		color: white;
		height: 100%;
	}

	.position-tip {
		position: fixed;
		right: 0px;
		top: 0px;
		width: 60px;
		background-color: black;
		color: white;
		height: 100%;
	}

	.question-bank-icon {
		font-size: 80px;
		color: rgb(236, 179, 238);
	}

	.circle-test {
		width: 5px;
		height: 5px;
		background-color: rgb(170, 163, 163);
		border-radius: 100%;
	}

	.three-icon {
		font-size: 40px;
		color: rgb(238, 170, 124);
	}

	.three-icon2 {
		font-size: 40px;
		color: rgb(71, 153, 207);
	}

	.three-icon3 {
		font-size: 40px;
		color: #11ba67;
	}

	.test-icon-three {
		width: 90%;
		margin-left: 5%;
	}

	.good-test {
		width: 90%;
		background-color: white;
		margin-left: 5%;
		height: 40px;
		border-radius: 4px;
		color: #11ba67;
		font-size: 16px;
		font-weight: 330;
		margin-top: 10px;
		border: #11ba67 1px solid;
	}

	.start-test {
		width: 90%;
		background-color: #11ba67;
		margin-left: 5%;
		height: 40px;
		border-radius: 4px;
		color: white;
		font-size: 16px;
		font-weight: 330;
		margin-top: 20px;
	}

	.ject-but:hover {
		transform: scale(1.1);
		color: rgb(248, 244, 244);
		background-color: #11ba67;
		border-radius: 2px;
	}

	.ject-but {
		border: none;
	}
</style>
