<template>
  <div class="flex items-center">
    <via-screenfull></via-screenfull>
  </div>
</template>

<script>
import ViaScreenfull from "@/components/ViaScreenfull";
export default {
  components: { ViaScreenfull },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>