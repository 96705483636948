<template>
	用法示例
</template>

<script>
	export default {
		name: "Yongfa",
	}
</script>

<style></style>
