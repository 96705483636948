<template>
  <div>
    <div class="font-thin text-sm bg-white p-4">
      筛选 ：<span
        class="font-light p-2"
        v-for="(question, index) in questions"
        :key="index"
      >
        {{ question.type }}
      </span>
    </div>
    <div class="font-light text-sm mt-2">
      <span class="ml-4">时间 </span>
      <span class="iconfont icon-jiantou_xiangxia text-sm"></span>
      <span class="ml-4">浏览次数</span>
      <span class="iconfont icon-jiantou_xiangxia text-sm"></span>
    </div>
    <section class="bg-white dark:bg-gray-900 mt-2">
      <div class="container border-b-2 border-gray-100 p-10 py-10 mx-auto">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="flex-1 min-w-0">
            <h2
              class="
                text-2xl
                font-bold
                leading-7
                text-gray-900
                sm:text-3xl sm:truncate
              "
            >
              2021-2022学年小学数学北师大版五年级寒假训练专题
            </h2>
            <div
              class="
                mt-1
                flex flex-col
                sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6
              "
            >
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <BriefcaseIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Full-time
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <LocationMarkerIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Remote
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <CurrencyDollarIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                $120k &ndash; $140k
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Closing on January 9, 2020
              </div>
            </div>
          </div>
          <div class="mt-5 flex lg:mt-0 lg:ml-4">
            <span class="hidden sm:block">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-gray-700
                  bg-white
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <PencilIcon
                  class="-ml-1 mr-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                Edit
              </button>
            </span>

            <span class="hidden sm:block ml-3">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-gray-700
                  bg-white
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <LinkIcon
                  class="-ml-1 mr-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                View
              </button>
            </span>

            <span class="sm:ml-3">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-indigo-600
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <CheckIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Publish
              </button>
            </span>

            <!-- Dropdown -->
            <Menu as="span" class="ml-3 relative sm:hidden">
              <MenuButton
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-gray-700
                  bg-white
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                More
                <ChevronDownIcon
                  class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    -mr-1
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >Edit</a
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >View</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <div class="container p-10 py-10 mx-auto">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="flex-1 min-w-0">
            <h2
              class="
                text-2xl
                font-bold
                leading-7
                text-gray-900
                sm:text-3xl sm:truncate
              "
            >
              2021-2022学年小学数学北师大版六年级寒假训练专题
            </h2>
            <div
              class="
                mt-1
                flex flex-col
                sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6
              "
            >
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <BriefcaseIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Full-time
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <LocationMarkerIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Remote
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <CurrencyDollarIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                $120k &ndash; $140k
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Closing on January 9, 2020
              </div>
            </div>
          </div>
          <div class="mt-5 flex lg:mt-0 lg:ml-4">
            <span class="hidden sm:block">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-gray-700
                  bg-white
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <PencilIcon
                  class="-ml-1 mr-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                Edit
              </button>
            </span>

            <span class="hidden sm:block ml-3">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-gray-700
                  bg-white
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <LinkIcon
                  class="-ml-1 mr-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                View
              </button>
            </span>

            <span class="sm:ml-3">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-indigo-600
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                <CheckIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Publish
              </button>
            </span>

            <!-- Dropdown -->
            <Menu as="span" class="ml-3 relative sm:hidden">
              <MenuButton
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-gray-700
                  bg-white
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                More
                <ChevronDownIcon
                  class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    -mr-1
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >Edit</a
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >View</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  LinkIcon,
  LocationMarkerIcon,
  PencilIcon,
} from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
export default {
  name: "Zhuanti",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BriefcaseIcon,
    CalendarIcon,
    CheckIcon,
    ChevronDownIcon,
    CurrencyDollarIcon,
    LinkIcon,
    LocationMarkerIcon,
    PencilIcon,
  },
  data() {
    return {
      questions: [
        { type: "全部" },
        { type: "同步练习" },
        { type: "期中备考" },
        { type: "期末备考" },
        { type: "寒假衔接" },
      ],
      isSelected: false,
      formModel: {
        user: "",
        password: "",
      },
      formRules: {
        user: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$refs.formRefs.validate();
        console.log("isValid", isValid);
        if (isValid) {
          this.$store.commit("user/setToken", "token");
          this.$router.replace({
            name: "home",
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>

<style></style>
