<template>
	<div>
		<header class="bg-white shadow">
			<div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
				<h1 class="text-3xl font-bold text-gray-900">
					建站服务	
				</h1>
			</div>
		</header>
		<main>
			<div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<!-- Replace with your content -->
				<div class="px-4 py-6 sm:px-0">
					<div class="border-4 border-dashed border-gray-200 rounded-lg h-96">
						sd
					</div>
				</div>
				<!-- /End replace -->
			</div>
		</main>
	</div>
</template>

<script>
	export default {
		name: "Api",
		data() {
			return {
				activeIndex: "1",
				tests: [{
						time: "01-17",
					},
					{
						title: "湘教版初中数学七年级下册1.3二元一次方程组的应用同步练习",
					},
					{
						title: "湘教版初中数学七年级下册1.3二元一次方程组的应用同步练习2",
					},
					{
						title: "湘教版初中数学七年级下册1.3二元一次方程组的应用同步练习3",
					},
				],
				list_img: [{
						url: "//tikupic.21cnjy.com/2022/01/15/55/4e/554e1f0de619954b8c9f6c4dfe128d4a.jpg",
					},
					{
						url: "//tikupic.21cnjy.com/2021/12/22/4e/d7/4ed743f47f9b481cc7e14c441550d796.png",
					},
					{
						url: "//tikupic.21cnjy.com/2021/12/22/82/3c/823c04e9e830d0b679f86933a30cfdbc.png",
					},
				],
				school_stage: [{
						title: "小学",
						subjects: [
							"语文",
							"数学3",
							"英语",
							"科学",
							"政d治思品（道德与法治）",
						],
					},
					{
						title: "中学",
						subjects: [
							"语文",
							"数3学",
							"英语5",
							"科学",
							"政治思7品（道德与法治）",
						],
					},
					{
						title: "高中",
						subjects: [
							"语文",
							"数学",
							"英8语",
							"科学7",
							"政7治思品（道德与法治）",
						],
					},
				],
			};
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
		},
	};
</script>

<style scoped>
	.tip-bottom:hover {
		cursor: pointer;
		transform: scale(1.1);
	}

	.tip-bottom {
		border: none;
	}

	.position-tip2 {
		position: fixed;
		right: 0px;
		top: 30px;
		width: 50px;
		background-color: black;
		color: white;
		height: 100%;
	}

	.position-tip {
		position: fixed;
		right: 0px;
		top: 0px;
		width: 60px;
		background-color: black;
		color: white;
		height: 100%;
	}

	.question-bank-icon {
		font-size: 80px;
		color: rgb(236, 179, 238);
	}

	.circle-test {
		width: 5px;
		height: 5px;
		background-color: rgb(170, 163, 163);
		border-radius: 100%;
	}

	.three-icon {
		font-size: 40px;
		color: rgb(238, 170, 124);
	}

	.three-icon2 {
		font-size: 40px;
		color: rgb(71, 153, 207);
	}

	.three-icon3 {
		font-size: 40px;
		color: #11ba67;
	}

	.test-icon-three {
		width: 90%;
		margin-left: 5%;
	}

	.good-test {
		width: 90%;
		background-color: white;
		margin-left: 5%;
		height: 40px;
		border-radius: 4px;
		color: #11ba67;
		font-size: 16px;
		font-weight: 330;
		margin-top: 10px;
		border: #11ba67 1px solid;
	}

	.start-test {
		width: 90%;
		background-color: #11ba67;
		margin-left: 5%;
		height: 40px;
		border-radius: 4px;
		color: white;
		font-size: 16px;
		font-weight: 330;
		margin-top: 20px;
	}

	.ject-but:hover {
		transform: scale(1.1);
		color: rgb(248, 244, 244);
		background-color: #11ba67;
		border-radius: 2px;
	}

	.ject-but {
		border: none;
	}
</style>
