module.exports = [
  {
    name: "test",
  },
  {
    name: "demo",
  },
  {
    name: "login",
    query: {},
    divided: true,
    logout: true,
  },
];
