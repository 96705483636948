import {
    mapRequest
} from "@/plugins/request/index";

// import { requestConfig } from "@/config/index";
// 测试环境


const mapRequestExe = mapRequest([
    //获取模拟数据
    {
        key: "getDemoData",
        value: "addons/market/api.Init/siteInfo",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        key: "getSiteInfo",
        value: "addons/market/api.Init/siteInfo",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "通用上传接口",
        key: "upload",
        value: "user/api.Common/upload",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "上传用户头像",
        key: "upload",
        value: "user/api.User/updateAvatar",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取用户信息",
        key: "getUserInfo",
        value: "user/api.UserLogin/getInfo",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "账号密码直接登录",
        key: "userLogin",
        value: "api/user/login",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "使用手机号注册账号",
        key: "userRegister",
        value: "user/api.UserRegister/registerByMobile",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },

    {
        title: "重置密码",
        key: "userPassword",
        value: "/user/api.UserPassword/resetPasswordByMobile",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取通用短信验证码",
        key: "sendSmsCode",
        value: "sms/api.Mobile/sendCode",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取图片验证码",
        key: "getCaptcha",
        value: "user/api.Common/captcha",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    //官方服务市场接口
    {
        title: "服务分类列表",
        key: "getCategoryList",
        value: "addons/market/api.category/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "服务列表",
        key: "getServiceList",
        value: "addons/market/api.service/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "服务详情",
        key: "getServiceInfo",
        value: "addons/market/api.service/detail",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取订单列表",
        key: "getOrderList",
        value: "addons/market/api.order/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取订单详情",
        key: "getOrderInfo",
        value: "addons/market/api.order/info",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "创建订单并返回支付链接",
        key: "createOrder",
        value: "addons/market/api.order/create",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取用户企业服务平台AppCode",
        key: "getAppCode",
        value: "addons/market/api.code/getCode",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "已开通API列表",
        key: "getApiList",
        value: "addons/market/api.api/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "短信签名列表",
        key: "getSmsSignList",
        value: "addons/market/api.SmsSign/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "创建短信签名",
        key: "createSmsSign",
        value: "addons/market/api.SmsSign/create",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "短信模板列表",
        key: "getSmsTemplateList",
        value: "addons/market/api.SmsTemplate/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "创建短信模板",
        key: "createSmsTemplate",
        value: "addons/market/api.SmsTemplate/create",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "短信日志列表",
        key: "getSmsLogList",
        value: "addons/market/api.SmsLog/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "短信套餐列表",
        key: "getSmsPackageList",
        value: "addons/market/api.SmsPackage/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }

    },
    {
        title: "api类型接口",
        key: "getApiType",
        value: "addons/market/api.Type/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }

    },
    {
        title: "修改用户信息",
        key: "changeUserInfo",
        value: "/user/api.User/updateInfo",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }

    },
    {
        title: "项目列表",
        key: "getProjectList",
        value: "project/api.project/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "新增项目",
        key: "createProject",
        value: "project/api.project/create",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "任务列表",
        key: "getTaskList",
        value: "project/api.task/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "新增任务",
        key: "createTask",
        value: "project/api.task/create",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "项目账号列表",
        key: "getAccountList",
        value: "project/api.Account/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "新增项目账号",
        key: "createAccount",
        value: "project/api.Account/create",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "修改用户绑定的手机号",
        key: "bindingMobilePhone",
        value: "/user/api.UserSafe/bindMobile",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取项目订单列表",
        key: "getProjectOrderList",
        value: "project/api.order/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取项目订单详情",
        key: "getProjectOrderInfo",
        value: "project/api.order/info",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "创建项目支付订单并返回支付链接",
        key: "createProjectOrder",
        value: "project/api.order/create",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    //题库模块接口配置
    //试卷列表
    {
        key: "getExamList",
        value: "tiku/api.Exam/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    //试题列表
    {
        key: "getQuestionList",
        value: "tiku/api.Question/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    //学段列表
    {
        key: "getStageList",
        value: "tiku/api.Stage/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },


    //章节列表
    {
        key: "getChapterList",
        value: "/tiku/api.Section/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },

    //知识点列表
    {
        key: "getKnowledgeList",
        value: "/tiku/api.Knowledge/index",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },

    // market

    {
        title: "用户登录",
        key: "marketUserLogin",
        value: "/user/api.UserLogin/loginQuick",
        options: {
            method: "post",

        }
    },
    {
        title: "用户注册",
        key: "marketUserRegister",
        value: "/user/api.UserRegister/registerByMobile",
        options: {
            method: "post",

        }
    },
    {
        title: "用户信息",
        key: "marketUserInfo",
        value: "/user/api.UserLogin/getInfo",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取会员实名认证信息",
        key: "getCertInfo",
        value: "/user/api.Certification/getCertInfo",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "身份证实名认证",
        key: "checkIdcardMatch",
        value: "/user/api.Certification/checkIdcardMatch",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "企业认证",
        key: "saveCompany",
        value: "/user/api.Company/saveCompany",
        options: {
            method: "post",
            isIntercept: false,
            isLoading: false
        }
    },
    {
        title: "获取企业认证信息",
        key: "getCompanyInfo",
        value: "/user/api.Company/getCompanyInfo",
        options: {
            method: "get",
            isIntercept: false,
            isLoading: false
        }
    },
]);

export default {
    install(app) {
        app.config.globalProperties.$req = mapRequestExe;
    },
    ...mapRequestExe
};