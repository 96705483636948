import {
    toast
} from "@/plugins/modal";
import request from "@/request";
export default {
    namespaced: true,
    state: () => ({
        categoryList: {}
    }),
    getters: {},
    mutations: {
        setCategoryList(state, data) {
            state.categoryList = data;
        }
    },
    actions: {
        //获取服务分类
        async getCategoryList({
            commit
        }, params = {}) {
            const data = await request.getCategoryList(params);
            commit("setCategoryList", data.data);
            return data;
        },
        //获取接口列表
        async getServiceList({}, params = {}) {
            const result = await request.getServiceList({}, {
                params
            });
            return result;
        },
        //获取接口详情
        async getServiceInfo({}, params = {}) {
            const result = await request.getServiceInfo({}, {
                params
            });
            return result;
        },
        //获取已开通服务列表
        async getApiList({}, params = {}) {
            const result = await request.getApiList({}, {
                params
            });
            return result;
        },
        //获取接口列表
        async getOrderList({}, params = {}) {
            const result = await request.getOrderList({}, {
                params
            });
            return result;
        },
        //创建订单，购买接口
        async createOrder({}, data = {}) {
            const result = await request.createOrder({}, {
                data
            });
            return result;
        },
        //获取接口详情
        async getOrderInfo({}, params = {}) {
            const result = await request.getOrderInfo({}, {
                params
            });
            return result;
        },
        //获取用户AppCode
        async getAppCode({}, params = {}) {
            const result = await request.getAppCode({}, {
                params
            });
            return result;
        },
        //获取会员实名认证信息
        async getCertInfo({}, params = {}) {
            const result = await request.getCertInfo({}, {
                params
            });
            return result;
        },
        //身份证实名认证
        async checkIdcardMatch({}, data = {}) {
            const result = await request.checkIdcardMatch({}, {
                data
            });
            return result;
        },
        //获取企业认证信息
        async getCompanyInfo({}, params = {}) {
            const data = await request.getCompanyInfo({}, {
                params
            });
            return data;
        },
        //进行企业认证
        async saveCompany({}, data = {}) {
            const result = await request.saveCompany({}, {
                data
            });
            return result;
        },
        //获取短信签名列表
        async getSmsSignList({}, params = {}) {
            const result = await request.getSmsSignList({}, {
                params
            });
            return result;
        },
        //创建短信签名
        async createSmsSign({}, data = {}) {
            const result = await request.createSmsSign({}, {
                data
            });
            return result;
        },
        //获取短信模板列表
        async getSmsTemplateList({}, params = {}) {
            const result = await request.getSmsTemplateList({}, {
                params
            });
            return result;
        },
        //创建短信模板
        async createSmsTemplate({}, data = {}) {
            const result = await request.createSmsTemplate({}, {
                data
            });
            return result;
        },
        //获取短信记录列表
        async getSmsLogList({}, params = {}) {
            const result = await request.getSmsLogList({}, {
                params
            });
            return result;
        },
        //获取短信套餐列表
        async getSmsPackageList({}, params = {}) {
            const result = await request.getSmsPackageList({}, {
                params
            });
            return result;
        },
        //获取api类型
        async getApiType({}, params = {}) {
            const result = await request.getApiType({}, {
                params
            });
            return result;
        },
        //获取项目列表
        async getProjectList({}, params = {}) {
            const result = await request.getProjectList({}, {
                params
            });
            return result;
        },
        //创建项目
        async createProject({}, data = {}) {
            const result = await request.createProject({}, {
                data
            });
            return result;
        },
        //获取任务列表
        async getTaskList({}, params = {}) {
            const result = await request.getTaskList({}, {
                params
            });
            return result;
        },
        //创建任务
        async createTask({}, data = {}) {
            const result = await request.createTask({}, {
                data
            });
            return result;
        },
        //项目账号列表
        async getAccountList({}, params = {}) {
            const result = await request.getAccountList({}, {
                params
            });
            return result;
        },
        //新增项目账号
        async createAccount({}, data = {}) {
            const result = await request.createAccount({}, {
                data
            });
            return result;
        },
        //获取项目订单列表
        async getProjectOrderList({}, params = {}) {
            const result = await request.getProjectOrderList({}, {
                params
            });
            return result;
        },
        //创建项目订单，返回支付链接
        async createProjectOrder({}, data = {}) {
            const result = await request.createProjectOrder({}, {
                data
            });
            return result;
        },
        //获取项目订单详情
        async getProjectOrderInfo({}, params = {}) {
            const result = await request.getProjectOrderInfo({}, {
                params
            });
            return result;
        },
    },


};