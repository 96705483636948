<template>

	<section class="bg-white dark:bg-gray-900">

		<div class="container p-10 py-10 mx-auto">
			<div class="container mx-auto shadow-lg rounded-lg">
				<!-- headaer -->
				<div class="px-5 py-5 flex justify-between items-center bg-white border-b-2">
					<div class="font-semibold text-2xl">GoingChat</div>
					<div class="w-1/2">
						<input type="text" name="" id="" placeholder="search IRL"
							class="rounded-2xl bg-gray-100 py-3 px-5 w-full" />
					</div>
					<div
						class="h-12 w-12 p-2 bg-yellow-500 rounded-full text-white font-semibold flex items-center justify-center">
						RA
					</div>
				</div>
				<!-- end header -->
				<!-- Chatting -->
				<div class="flex flex-row justify-between bg-white">
					<!-- chat list -->
					<div class="flex flex-col w-2/5 border-r-2 overflow-y-auto">
						<!-- search compt -->
						<div class="border-b-2 py-4 px-2">
							<input type="text" placeholder="search chatting"
								class="py-2 px-2 border-2 border-gray-200 rounded-2xl w-full" />
						</div>
						<!-- end search compt -->
						<!-- user list -->
						<div class="flex flex-row py-4 px-2 justify-center items-center border-b-2">
							<div class="w-1/4">
								<img src="https://source.unsplash.com/_7LbC5J-jw4/600x600"
									class="object-cover h-12 w-12 rounded-full" alt="" />
							</div>
							<div class="w-full">
								<div class="text-lg font-semibold">Luis1994</div>
								<span class="text-gray-500">Pick me at 9:00 Am</span>
							</div>
						</div>
						<div class="flex flex-row py-4 px-2 items-center border-b-2">
							<div class="w-1/4">
								<img src="https://source.unsplash.com/otT2199XwI8/600x600"
									class="object-cover h-12 w-12 rounded-full" alt="" />
							</div>
							<div class="w-full">
								<div class="text-lg font-semibold">Everest Trip 2021</div>
								<span class="text-gray-500">Hi Sam, Welcome</span>
							</div>
						</div>
						<div class="flex flex-row py-4 px-2 items-center border-b-2 border-l-4 border-blue-400">
							<div class="w-1/4">
								<img src="https://source.unsplash.com/L2cxSuKWbpo/600x600"
									class="object-cover h-12 w-12 rounded-full" alt="" />
							</div>
							<div class="w-full">
								<div class="text-lg font-semibold">MERN Stack</div>
								<span class="text-gray-500">Lusi : Thanks Everyone</span>
							</div>
						</div>
			  	<div class="flex flex-row py-4 px-2 items-center border-b-2">
							<div class="w-1/4">
								<img src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-12 w-12 rounded-full" alt="" />
							</div>
							<div class="w-full">
								<div class="text-lg font-semibold">Javascript Indonesia</div>
								<span class="text-gray-500">Evan : some one can fix this</span>
							</div>
						</div>
						<div class="flex flex-row py-4 px-2 items-center border-b-2">
							<div class="w-1/4">
								<img src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-12 w-12 rounded-full" alt="" />
							</div>
							<div class="w-full">
								<div class="text-lg font-semibold">Javascript Indonesia</div>
								<span class="text-gray-500">Evan : some one can fix this</span>
							</div>
						</div>

						<div class="flex flex-row py-4 px-2 items-center border-b-2">
							<div class="w-1/4">
								<img src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-12 w-12 rounded-full" alt="" />
							</div>
							<div class="w-full">
								<div class="text-lg font-semibold">Javascript Indonesia</div>
								<span class="text-gray-500">Evan : some one can fix this</span>
							</div>
						</div>
						<!-- end user list -->
					</div>
					<!-- end chat list -->
					<!-- message -->
			  <div class="w-full px-5 flex flex-col justify-between">
						<div class="flex flex-col mt-5">
							<div class="flex justify-end mb-4">
								<div
									class="mr-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white">
									Welcome to group everyone !
								</div>
								<img src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-8 w-8 rounded-full" alt="" />
							</div>
							<div class="flex justify-start mb-4">
								<img src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-8 w-8 rounded-full" alt="" />
								<div
			  				class="ml-2 py-3 px-4 bg-gray-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white">
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
									at praesentium, aut ullam delectus odio error sit rem. Architecto
									nulla doloribus laborum illo rem enim dolor odio saepe,
									consequatur quas?
								</div>
							</div>
							<div class="flex justify-end mb-4">
								<div>
									<div
										class="mr-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white">
										Lorem ipsum dolor, sit amet consectetur adipisicing elit.
										Magnam, repudiandae.
									</div>

									<div
										class="mt-4 mr-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Debitis, reiciendis!
									</div>
								</div>
								<img src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-8 w-8 rounded-full" alt="" />
							</div>
							<div class="flex justify-start mb-4">
								<img src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-8 w-8 rounded-full" alt="" />
								<div
									class="ml-2 py-3 px-4 bg-gray-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white">
									happy holiday guys!
								</div>
							</div>
						</div>
						<div class="py-5">
							<input class="w-full bg-gray-300 py-5 px-3 rounded-xl" type="text"
								placeholder="type your message here..." />
						</div>
					</div>
					<!-- end message -->
					<div class="w-2/5 border-l-2 px-5">
						<div class="flex flex-col">
							<div class="font-semibold text-xl py-4">Mern Stack Group</div>
							<img src="https://source.unsplash.com/L2cxSuKWbpo/600x600"
								class="object-cover rounded-xl h-64" alt="" />
							<div class="font-semibold py-4">Created 22 Sep 2021</div>
							<div class="font-light">
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
								perspiciatis!
							</div>
						</div>
					</div>
				</div>

		</div>

		</div>
	</section>
</template>

<script>
	export default {
		name: "New",
		data() {
			return {
				isSelected: false,
				formModel: {
					user: "",
					password: "",
				},
				formRules: {
					user: [{
						required: true,
						message: "用户名不能为空",
						trigger: "blur"
					}],
					password: [{
						required: true,
						message: "密码不能为空",
						trigger: "blur"
					}, ],
				},
			};
		},
		methods: {
			async submit() {
				try {
					const isValid = await this.$refs.formRefs.validate();
					console.log("isValid", isValid);
					if (isValid) {
						this.$store.commit("user/setToken", "token");
						this.$router.replace({
							name: "home"
						});
					}
				} catch (error) {
					// console.log(error);
				}
			},
		},
	};
</script>

<style>
	.juice {
		background-image: url('https://i.ibb.co/SN2Sp4T/juice.png');
	}

	.juice2 {
		background-image: url('https://i.ibb.co/yyMXMSF/juice2.png');
	}

	.juice3 {
		z-index: 10;
		position: relative;
	}

	.juice3::after {
		content: '';
		position: absolute;
		opacity: .2;
		z-index: -999;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url('https://previews.123rf.com/images/olgasiv/olgasiv1403/olgasiv140300026/27343111-image-en-noir-sur-un-fond-blanc-dessin-de-l%C3%A9gumes-de-fruits-et-de-baies-.jpg');
	}

	.text-primary {
		color: #f9b529;
	}

	.text-primary-lite {
		color: #fac251;
	}

	.text-secondary {
		color: #294356;
	}

	.text-secondary-lite {
		color: #d5dee5;
	}

	.bg-primary {
		background-color: #f9b529;
	}

	.bg-primary-lite {
		background-color: #fac251;
	}

	.bg-secondary {
		background-color: #294356;
	}

	.bg-secondary-lite {
		background-color: #d5dee5;
	}

	.product {
		background-image: url('https://i.ibb.co/L00dH6V/2021-11-07-14h07-51.png');
	}

	.product2 {
		background-image: url('https://i.ibb.co/1fZMKPh/2021-11-07-14h08-07.png');
	}

	.product3 {
		background-image: url('https://i.ibb.co/f9tpvV3/2021-11-07-14h08-32.png');
	}

	.product4 {
		background-image: url('https://i.ibb.co/42BsKQ2/2021-11-07-14h08-17.png');
	}
</style>
