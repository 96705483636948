<template>
  <div class="container flex mx-auto">
    <div class="w-1/4 mr-2 bg-white divide-y-2 mt-5">
      <div class="p-5">
        <Menu as="div" class="relative inline-block text-left">
          <div class="flex items-center">
            <p class="text-xl ml-2">人教版 · 七年级上册</p>

            <MenuButton
              class="
                inline-flex
                justify-center
                rounded-md
                border border-gray-300
                shadow-sm
                mx-3
                px-4
                py-2
                bg-white
                text-sm
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-100
                focus:ring-indigo-500
              "
            >
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="
                origin-top-right
                absolute
                right-0
                mt-2
                w-56
                rounded-md
                shadow-lg
                bg-white
                ring-1 ring-black ring-opacity-5
                divide-y divide-gray-100
                focus:outline-none
              "
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Edit</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Duplicate</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Archive</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Move</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Share</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Add to favorites</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Delete</a
                  >
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <div class="p-5">
        <el-tree
          :data="KnowledgeList"
          show-checkbox
          node-key="id"
          :default-expanded-keys="[2, 3]"
          :default-checked-keys="[5]"
        >
        </el-tree>
      </div>
    </div>
    <div class="container w-3/4 p-4 mt-1">
      <div class="flex bg-white">
        <div class="flex-1">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            active-text-color="green"
          >
            <el-menu-item class="font-light" index="1">试题</el-menu-item>
            <el-menu-item class="font-light" index="2">同步套题</el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="container bg-white mt-4 font-light text-sm p-4">
        <span>最新试卷</span>
        <span class="ml-4">全部试卷</span>
      </div>

      <section class="bg-white dark:bg-gray-900 mt-2">
        <div class="container border-b-2 border-gray-100 p-10 py-10 mx-auto">
          <div class="lg:flex lg:items-center lg:justify-between">
            <div class="flex-1 min-w-0">
              <h2 class="leading-7">
                2021-2022学年小学数学北师大版五年级寒假训练专题
              </h2>
              <div
                class="
                  mt-1
                  flex flex-col
                  sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6
                "
              >
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <BriefcaseIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Full-time
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <LocationMarkerIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Remote
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <CurrencyDollarIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  $120k &ndash; $140k
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Closing on January 9, 2020
                </div>
              </div>
            </div>
            <div class="mt-5 flex lg:mt-0 lg:ml-4">
              <span class="hidden sm:block">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <PencilIcon class="text-gray-500" aria-hidden="true" />

                  Edit
                </button>
              </span>

              <span class="hidden sm:block ml-3">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <LinkIcon class="text-gray-500" aria-hidden="true" />
                  View
                </button>
              </span>

              <span class="sm:ml-3">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-indigo-600
                    hover:bg-indigo-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <CheckIcon class="" aria-hidden="true" />
                  Publish
                </button>
              </span>

              <!-- Dropdown -->
              <Menu as="span" class="ml-3 relative sm:hidden">
                <MenuButton
                  class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  More
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </MenuButton>

                <transition
                  enter-active-class="transition ease-out duration-200"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="
                      origin-top-right
                      absolute
                      right-0
                      mt-2
                      -mr-1
                      w-48
                      rounded-md
                      shadow-lg
                      py-1
                      bg-white
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                    "
                  >
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >Edit</a
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >View</a
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
        <div class="container border-b-2 border-gray-100 p-10 py-10 mx-auto">
          <div class="lg:flex lg:items-center lg:justify-between">
            <div class="flex-1 min-w-0">
              <h2 class="leading-7">
                2021-2022学年小学数学北师大版五年级寒假训练专题
              </h2>
              <div
                class="
                  mt-1
                  flex flex-col
                  sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6
                "
              >
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <BriefcaseIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Full-time
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <LocationMarkerIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Remote
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <CurrencyDollarIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  $120k &ndash; $140k
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Closing on January 9, 2020
                </div>
              </div>
            </div>
            <div class="mt-5 flex lg:mt-0 lg:ml-4">
              <span class="hidden sm:block">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <PencilIcon class="text-gray-500" aria-hidden="true" />

                  Edit
                </button>
              </span>

              <span class="hidden sm:block ml-3">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <LinkIcon class="text-gray-500" aria-hidden="true" />
                  View
                </button>
              </span>

              <span class="sm:ml-3">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-indigo-600
                    hover:bg-indigo-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <CheckIcon class="" aria-hidden="true" />
                  Publish
                </button>
              </span>

              <!-- Dropdown -->
              <Menu as="span" class="ml-3 relative sm:hidden">
                <MenuButton
                  class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  More
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </MenuButton>

                <transition
                  enter-active-class="transition ease-out duration-200"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="
                      origin-top-right
                      absolute
                      right-0
                      mt-2
                      -mr-1
                      w-48
                      rounded-md
                      shadow-lg
                      py-1
                      bg-white
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                    "
                  >
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >Edit</a
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >View</a
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
        <div class="container border-b-2 border-gray-100 p-10 py-10 mx-auto">
          <div class="lg:flex lg:items-center lg:justify-between">
            <div class="flex-1 min-w-0">
              <h2 class="leading-7">
                2021-2022学年小学数学北师大版五年级寒假训练专题
              </h2>
              <div
                class="
                  mt-1
                  flex flex-col
                  sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6
                "
              >
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <BriefcaseIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Full-time
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <LocationMarkerIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Remote
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <CurrencyDollarIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  $120k &ndash; $140k
                </div>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Closing on January 9, 2020
                </div>
              </div>
            </div>
            <div class="mt-5 flex lg:mt-0 lg:ml-4">
              <span class="hidden sm:block">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <PencilIcon class="text-gray-500" aria-hidden="true" />

                  Edit
                </button>
              </span>

              <span class="hidden sm:block ml-3">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <LinkIcon class="text-gray-500" aria-hidden="true" />
                  View
                </button>
              </span>

              <span class="sm:ml-3">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-7
                    py-2
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-indigo-600
                    hover:bg-indigo-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <CheckIcon class="" aria-hidden="true" />
                  Publish
                </button>
              </span>

              <!-- Dropdown -->
              <Menu as="span" class="ml-3 relative sm:hidden">
                <MenuButton
                  class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  More
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </MenuButton>

                <transition
                  enter-active-class="transition ease-out duration-200"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="
                      origin-top-right
                      absolute
                      right-0
                      mt-2
                      -mr-1
                      w-48
                      rounded-md
                      shadow-lg
                      py-1
                      bg-white
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                    "
                  >
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >Edit</a
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >View</a
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
export default {
  name: "Zhangjie",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  data() {
    return {
      KnowledgeList: [],
      selectList: [
        {
          title: "题型",
          children: [
            { label: "全部", value: "quanbu" },
            { label: "单选", value: "danxuan" },
            { label: "判断", value: "panduan" },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "难度",
          children: [
            { label: "全部", value: "quanbu" },
            { label: "容易", value: "rongyi" },
            { label: "简单", value: "jd" },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "方式",
          children: [
            { label: "全部", value: "quanbu" },
            { label: "关联出题", value: "guanlainchuti" },
            { label: "精准出题", value: "jingzhunchuti" },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "更多",
          children: [
            { label: "全部", value: "quanbu" },
            { label: "题类", value: "tilei" },
            { label: "年级", value: "nianji" },
            { label: "地区", value: "diqu" },
            { label: "年份", value: "nianfen" },
            { label: "过滤已用", value: "guolvyiyong" },
            { label: "防超纲选题", value: "fangchaogangxuanti" },
          ],
          activeIndex: "quanbu",
        },
      ],
      activeIndex: "1",
      questionList: [
        {
          questiontype: "1",
        },
      ],
      data: [
        {
          id: 1,
          label: "知识点选题",
          children: [
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 2,
              label: "二级 2-2",
              disabled: true,
              children: [
                {
                  id: 6,
                  label: "三级 3-2-1",
                },
                {
                  id: 7,
                  label: "三级 3-2-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      isSelected: false,
      formModel: {
        user: "",
        password: "",
      },
      formRules: {
        user: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async getKnowledgeList() {
      const data = await this.$store.dispatch("tiku/getKnowledgeList");
      console.log("KnowledgeList", data);
      this.KnowledgeList = [data.data];
    },
    itemTap(index, value) {
      this.selectList[index].activeIndex = value;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    async submit() {
      try {
        const isValid = await this.$refs.formRefs.validate();
        console.log("isValid", isValid);
        if (isValid) {
          this.$store.commit("user/setToken", "token");
          this.$router.replace({
            name: "home",
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  mounted() {
    this.getKnowledgeList();
  },
};
</script>

<style lang="scss">
.result-search {
  border: rgb(219, 215, 215) 1px solid;
  border-radius: 3px;
}
.zhangjie-clsss {
  color: red;
  font-size: 14px;
}
.list {
  margin-top: 10px;
  .list-child-btn-active {
    padding: 5px 10px;
    border-radius: 6px;
    background-color: #2bbb61;
    color: #ffffff;
    margin: 0 8px;
  }
  .list-child-btn-none {
    padding: 5px 10px;
    border-radius: 6px;
    color: #000000;
    margin: 0 8px;
  }
}
</style>
