<template>
	<layout>
		<router-view v-slot="{ Component }">
			<via-fade-transform>
				<component :is="Component" />
			</via-fade-transform>
		</router-view>
	</layout>
</template>

<script>
	import Layout from "@/views/tiku/components/Layout";
	import ViaFadeTransform from "@/components/ViaFadeTransform";
	export default {
		name: "Tiku",
		components: {
			Layout,
			ViaFadeTransform
		},
		data() {
			return {
				siteInfo: {
					site_info: {
						title: ''
					},
					site_data: {

					},
					slider_list: {

					},
				},
			};
		},
		created() {
			this.getSiteInfo()
		},
		methods: {
			async getSiteInfo() {
				const data = await this.$store.dispatch("site/getSiteInfo");
				console.log(data);
				this.siteInfo = data.data;
			},
		},
	};
</script>

<style></style>
