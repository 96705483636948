import { toast } from "@/plugins/modal";
import request from "@/request";
export default {
    namespaced: true,
    state: () => ({
        demoData: "hello word"
    }),
    getters: {},
    mutations: {
        setDemoData(state, data) {
            state.demoData = data;
        }
    },
    actions: {
        //获取学段列表
        async getStageList({}, { params = {}, options = {} } = {}) {
            // const { result: data } = await request.getSiteInfo(params, options);
            const data = await request.getStageList(params, options);
            return data;
        },
        //试卷列表
        async getExamList({}, { params = {}, options = {} } = {}) {
            // const { result: data } = await request.getSiteInfo(params, options);
            const data = await request.getExamList(params, options);
            return data;
        },


        //专题列表

        //章节列表 
        async getChapterList({}, { params = {}, options = {} } = {}) {
            // const { result: data } = await request.getSiteInfo(params, options);
            const data = await request.getChapterList(params, options);
            return data;
        },

        //试题列表
        async getQuestionList({}, { params = {}, options = {} } = {}) {
            // const { result: data } = await request.getSiteInfo(params, options);
            const data = await request.getQuestionList(params, options);
            return data;
        },

        //知识点列表
        async getKnowledgeList({}, { params = {}, options = {} } = {}) {
            // const { result: data } = await request.getSiteInfo(params, options);
            const data = await request.getKnowledgeList(params, options);
            return data;
        }
    }

};