<template>
  <div class="flex-1 relative z-0 flex overflow-hidden">
    <aside
      class="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200 overflow-y-auto"
    >
      <!-- Start secondary column (hidden on smaller screens) -->
      <div class="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
        <div class="flex items-center flex-shrink-0 px-4">项目管理</div>
        <div class="mt-5 flex-grow flex flex-col">
          <nav class="flex-1 px-2 space-y-1 bg-white">
            <template v-for="item in navigation" :key="item.name">
              <div v-if="!item.children">
                <a
                  :href="item.href"
                  :class="[
                    $route.href.includes(`${item.href}`)
                      ? 'bg-gray-100 text-gray-900'
                      : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group w-full flex items-center pl-2 py-4 text-sm font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      $route.href.includes(`${item.href}`)
                        ? 'text-gray-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </div>
              <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
                <DisclosureButton
                  :class="[
                    $route.href.includes(`${item.href}`)
                      ? 'bg-gray-100 text-gray-900'
                      : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group w-full flex items-center pl-2 pr-1 py-4 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                  ]"
                >
                  <component
                    :is="item.icon"
                    class="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span class="flex-1">
                    {{ item.name }}
                  </span>
                  <svg
                    :class="[
                      $route.href.includes(`${item.href}`)
                        ? 'text-gray-400 rotate-90'
                        : 'text-gray-300',
                      'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                    ]"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                  </svg>
                </DisclosureButton>
                <DisclosurePanel class="space-y-1">
                  <DisclosureButton
                    v-for="subItem in item.children"
                    :key="subItem.name"
                    as="a"
                    :href="subItem.href"
                    :class="[
                      subItem.href == $route.href
                        ? 'bg-gray-100 text-gray-900'
                        : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50',
                    ]"
                  >
                    {{ subItem.name }}
                  </DisclosureButton>
                </DisclosurePanel>
              </Disclosure>
            </template>
          </nav>
        </div>
      </div>
      <!-- End secondary column -->
    </aside>
    <main
      class="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last"
    >
      <!-- Start main area-->
      <div class="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
        <router-view v-slot="{ Component }">
          <via-fade-transform>
            <component :is="Component" />
          </via-fade-transform>
        </router-view>
      </div>
      <!-- End main area -->
    </main>
  </div>
</template>

<script>
import Layout from "@/views/my/components/Layout";
import ViaFadeTransform from "@/components/ViaFadeTransform";
import {
  Dialog,
  DialogOverlay,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  CogIcon,
  CollectionIcon,
  HomeIcon,
  MenuAlt2Icon,
  PhotographIcon,
  PlusSmIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
  UsersIcon,
  FolderIcon,
  InboxIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
const navigation = [
  {
    name: "项目列表",
    href: "#/my/project/joined",
    icon: HomeIcon,
    current: false,
  },
  {
    name: "任务列表",
    href: "#/my/project/task",
    icon: UsersIcon,
    current: false,
  },
  {
    name: "项目动态",
    href: "#/my/project/activity",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "账号信息",
    href: "#/my/project/pacount",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "项目文件",
    href: "#/my/project/pfile",
    icon: FolderIcon,
    current: false,
  },
  {
    name: "项目部署",
    href: "#/my/project/pdeploy",
    icon: FolderIcon,
    current: false,
  },
];
export default {
  name: "Sms",
  components: {
    Layout,
    ViaFadeTransform,
    Dialog,
    DialogOverlay,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    MenuAlt2Icon,
    PlusSmIcon,
    SearchIcon,
    XIcon,
    UsersIcon,
    FolderIcon,
    InboxIcon,
  },
  data() {
    return {
      siteInfo: {
        site_info: {
          title: "",
        },
      },
    };
  },
  created() {
    // this.getSiteInfo();
  },
  methods: {
    async getSiteInfo() {
      const data = await this.$store.dispatch("site/getSiteInfo");
      console.log(data);
      this.siteInfo = data.data;
    },
  },
  setup() {
    return {
      navigation,
    };
  },
};
</script>

<style></style>
