<template>
  <div>
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold text-gray-900">签名管理</h1>
      </div>
    </header>
    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <section class="bg-white dark:bg-gray-900">
          <div class="container p-10 py-10 mx-auto">
            <el-button class="mb-10" @click="dialogFormVisible = true"
              >添加签名</el-button
            >

            <el-table :data="list.data" style="width: 100%">
              <el-table-column prop="id" label="签名ID" width="200">
              </el-table-column>
              <el-table-column prop="title" label="签名名称"> </el-table-column>
              <el-table-column prop="create_time" label="创建时间">
              </el-table-column>
              <el-table-column label="审核状态">
                <template v-slot="scope">
                  <span v-show="scope.row.status == 1">通过</span>
                  <span v-show="scope.row.status == 0">审核中</span>
                  <span v-show="scope.row.status == -1">
                    未通过
                    <el-button class=" " @click="reApply(scope.row)"
                      >重新修改</el-button
                    >
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <div class="flex-x-center mt-4">
              <el-pagination
                background
                :page-size="10"
                @current-change="hanpages"
                layout="prev, pager, next"
                :total="list.total"
              >
              </el-pagination>
            </div>
          </div>
        </section>
        <!-- /End replace -->
      </div>
    </main>
    <el-dialog title="添加签名" v-model="dialogFormVisible">
      <span class="text-red-500 mb-5 flex" v-if="signModel.message"
        >审核提示：{{ signModel.message }}</span
      >
      <el-form :model="signModel" :rules="formRules" ref="formRefs">
        <el-form-item
          label="签名名称"
          :label-width="formLabelWidth"
          prop="title"
        >
          <el-input v-model="signModel.title" autocomplete="off"></el-input>
          <div class="ml-5">
            <ul class="list-disc leading-6 text-gray-500">
              <li>
                签名内容为短信发送方的已上线应用名称、已备案的网站名称、公司名称、商标名称等
              </li>
              <li>若涉及到第三方权益必须获得第三方真实授权</li>
              <li>不支持如“客户服务”、“友情提醒”等过于宽泛内容</li>
              <li>不支持带有“测试”字样的签名，建议以实际已上线业务进行测试</li>
              <li>若签名／模版内容侵犯到第三方权益必须获得第三方真实授权</li>
              <li>
                无须添加【】、()、[]符号，签名发送会自带【】符号，避免重复
              </li>
            </ul>
          </div>
        </el-form-item>
        <el-form-item
          label="申请说明"
          :label-width="formLabelWidth"
          prop="description"
        >
          <el-input
            type="textarea"
            v-model="signModel.description"
            autocomplete="off"
            placeholder="请描述详细的使用场景，并提供产品链接、App、公众号、公司标准全称等，例：
使用场景：向会员用户推送与XX相关的营销活动
产品链接：market.gepardshop.com"
            rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flex-x-center">
        <el-button type="primary" class="" @click="submit"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Sign",
  data() {
    return {
      page: 1,
      list: [],
      dialogFormVisible: false,
      formLabelWidth: "120px",
      signModel: {
        id: "",
        title: "",
        description: "",
        message: "",
      },
      formRules: {
        username: [
          {
            required: true,
            message: "签名名称不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "申请说明不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getSmsSignList();
    console.log(this.list);
  },
  methods: {
    reApply(row) {
      this.signModel.id = row.id;
      this.signModel.title = row.title;
      this.signModel.description = row.description;
      this.signModel.message = row.message;
      this.dialogFormVisible = true;
    },
    async submit() {
      //调用登录接口
      const res = await this.$store.dispatch(
        "market/createSmsSign",
        this.signModel
      );

      if (res.code == 1) {
        //获取用户信息
        this.$message({
          message: res.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.getSmsSignList();
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    hanpages(v) {
      this.page = v;
    },
    async getSmsSignList() {
      const res = await this.$store.dispatch("market/getSmsSignList", {
        page: this.page,
      });
      console.log(res.data);
      if (res.code == 1) {
        this.list = res.data;
      }
    },
  },
  watch: {
    page: {
      deep: true,
      handler: function (newV, oldV) {
        this.getSmsSignList();
      },
    },
  },
};
</script>

<style scoped>
.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 60px;
  background-color: black;
  color: white;
  height: 100%;
}

.question-bank-icon {
  font-size: 80px;
  color: rgb(236, 179, 238);
}

.circle-test {
  width: 5px;
  height: 5px;
  background-color: rgb(170, 163, 163);
  border-radius: 100%;
}

.three-icon {
  font-size: 40px;
  color: rgb(238, 170, 124);
}

.three-icon2 {
  font-size: 40px;
  color: rgb(71, 153, 207);
}

.three-icon3 {
  font-size: 40px;
  color: #11ba67;
}

.test-icon-three {
  width: 90%;
  margin-left: 5%;
}

.good-test {
  width: 90%;
  background-color: white;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: #11ba67;
  font-size: 16px;
  font-weight: 330;
  margin-top: 10px;
  border: #11ba67 1px solid;
}

.start-test {
  width: 90%;
  background-color: #11ba67;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 330;
  margin-top: 20px;
}

.ject-but:hover {
  transform: scale(1.1);
  color: rgb(248, 244, 244);
  background-color: #11ba67;
  border-radius: 2px;
}

.ject-but {
  border: none;
}
</style>
