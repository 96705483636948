<template>
  <div
    class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div class="min-h-80">
        <div class="text-2xl md:text-3xl text-center">登录</div>
        <el-form
          ref="formRefs"
          :model="formModel"
          :rules="formRules"
          class="mt-8"
        >
          <el-form-item prop="account">
            <el-input v-model="formModel.account" placeholder="请输入用户名">
              <template #prefix>
                <via-svg-icon name="user" class="text-2xl"></via-svg-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="formModel.password"
              placeholder="请输入用户密码"
              type="password"
              @keyup.enter.native="submit"
            >
              <template #prefix>
                <via-svg-icon name="password" class="text-xl"></via-svg-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="flex items-center justify-between">
              <el-checkbox v-model="isSelected" size="medium">
                记住密码
              </el-checkbox>
              <!-- <el-button
	         type="text"
	         class="text-gray  hover:text-black"
	       >
	         去注册
	       </el-button> -->
              <a
                href="#/market/register"
                class="text-gray-400 hover:text-gray-900 text-sm"
              >
                注册平台账号
              </a>
              <a
                href="#/market/forget"
                class="text-gray-400 hover:text-gray-900 text-sm"
              >
                忘记密码
              </a>
            </div>
            <el-button
              type="primary"
              size="medium"
              class="w-full"
              @click="submit"
            >
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LockClosedIcon } from "@heroicons/vue/solid";
const navigation = [];

export default {
  name: "Login",
  components: {
    LockClosedIcon,
  },
  setup() {
    return {
      navigation,
    };
  },
  computed: {
    ...mapGetters({
      demoData: "demoData",
      userInfo: "userInfo",
    }),
  },
  mounted() {
    if (this.userInfo.id > 0) {
      this.$router.replace({
        name: "api",
      });
    }
  },
  data() {
    return {
      isSelected: false,
      formModel: {
        account: "",
        password: "",
      },
      formRules: {
        account: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async submit() {
      try {
        const isValid = await this.$refs.formRefs.validate();
        if (isValid) {
          //调用登录接口
          const data = await this.$store.dispatch(
            "user/userLogin",
            this.formModel
          );

          if (data) {
            //获取用户信息

            this.$router.replace({
              name: "market",
            });
          }
        }
      } catch (error) {
        this.$message({
          message: error,
          type: "error",
        });
        console.log(error);
      }
    },
    toLoigin() {
      alert("去登陆");
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 60px;
  background-color: black;
  color: white;
  height: 100%;
}

.question-bank-icon {
  font-size: 80px;
  color: rgb(236, 179, 238);
}

.circle-test {
  width: 5px;
  height: 5px;
  background-color: rgb(170, 163, 163);
  border-radius: 100%;
}

.three-icon {
  font-size: 40px;
  color: rgb(238, 170, 124);
}

.three-icon2 {
  font-size: 40px;
  color: rgb(71, 153, 207);
}

.three-icon3 {
  font-size: 40px;
  color: #11ba67;
}

.test-icon-three {
  width: 90%;
  margin-left: 5%;
}

.good-test {
  width: 90%;
  background-color: white;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: #11ba67;
  font-size: 16px;
  font-weight: 330;
  margin-top: 10px;
  border: #11ba67 1px solid;
}

.start-test {
  width: 90%;
  background-color: #11ba67;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 330;
  margin-top: 20px;
}

.ject-but:hover {
  transform: scale(1.1);
  color: rgb(248, 244, 244);
  background-color: #11ba67;
  border-radius: 2px;
}

.ject-but {
  border: none;
}
</style>
