<template>
  <div>
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold text-gray-900">企业应用</h1>
        <div class="lg:flex lg:items-center lg:justify-between mt-5">
          <div class="flex-1 min-w-0">
            精选内容管理、电商、直播、论坛系统等全端建站服务,精选企业业务管理、知识管理、客服及营销推广等解决方案
            <div class="flex mt-5">
              <div class="flex items-center mr-5">
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 27"
                  class="h-7 w-7 -mt-2 flex-shrink-0"
                >
                  <path
                    d="M22.487.658s5.03 13.072-1.822 22.171C16.476 28.39 9.84 27.26 5.484 25.68c1.513-3.391 3.441-6.067 5.784-8.03 1.176.623 3.186.792 6.03.51-2.535-.221-4.284-.654-5.246-1.3l.125.08c2.122-1.546 4.556-2.556 7.303-3.029-3.16-.285-6.026.315-8.598 1.804-.577-.742-1.157-1.748-1.74-3.018.07 1.534.339 2.734.809 3.6-2.64 1.797-4.953 4.58-6.94 8.351a7.583 7.583 0 01-.188-.088c-.802-.358-1.328-1.037-1.755-2.036C-1.9 13.366 4.645 8.273 11.123 7.989 23.046 7.465 22.487.658 22.487.658z"
                    fill="#0ED3CF"
                  ></path>
                </svg>
                <p class="ml-2">极速对接</p>
              </div>
              <div class="flex items-center">
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 27"
                  class="h-7 w-7 -mt-2 flex-shrink-0"
                >
                  <path
                    d="M22.487.658s5.03 13.072-1.822 22.171C16.476 28.39 9.84 27.26 5.484 25.68c1.513-3.391 3.441-6.067 5.784-8.03 1.176.623 3.186.792 6.03.51-2.535-.221-4.284-.654-5.246-1.3l.125.08c2.122-1.546 4.556-2.556 7.303-3.029-3.16-.285-6.026.315-8.598 1.804-.577-.742-1.157-1.748-1.74-3.018.07 1.534.339 2.734.809 3.6-2.64 1.797-4.953 4.58-6.94 8.351a7.583 7.583 0 01-.188-.088c-.802-.358-1.328-1.037-1.755-2.036C-1.9 13.366 4.645 8.273 11.123 7.989 23.046 7.465 22.487.658 22.487.658z"
                    fill="#0ED3CF"
                  ></path>
                </svg>
                <p class="ml-2">省心服务</p>
              </div>
            </div>
          </div>
          <div v-if="userInfo.id" class="mt-5 flex lg:mt-0 lg:ml-4">
            <a
              href="#/my/service"
              class="p-2 bg-green-500 rounded-md text-white font-semibold flex items-center justify-center"
            >
              我的服务
            </a>
          </div>
        </div>
      </div>
    </header>
    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div
          class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-8 md:grid-cols-2 xl:grid-cols-4"
        >
          <div
            v-for="(item, index) in serviceList.data"
            :key="index"
            class="p-4 bg-white space-y-3 dark:border-blue-300 rounded-xl point"
            @click="$router.push({ name: 'product', query: { id: item.id } })"
          >
            <div class="flex-x-center">
              <span class="inline-block text-blue-500 dark:text-blue-400">
                <img :src="item.image" alt="" />
              </span>
            </div>

            <h1
              class="text-xl font-semibold text-gray-700 capitalize dark:text-white"
            >
              {{ item.title }}
            </h1>

            <p class="text-gray-500 dark:text-gray-300 enterprise-describe">
              {{ item.description }}
            </p>
            <!-- <p class="text-red-500">¥{{ item.discount_price }}元/次</p> -->
            <div class="text-gray-500">
              <span class="line-through">¥{{ item.price }}元 </span>
              <span class="text-red"> ¥{{ item.discount_price }}元</span>
            </div>
          </div>
        </div>
        <div class="flex-x-center mt-4">
          <el-pagination
            background
            :page-size="16"
            @current-change="hanpages"
            layout="prev, pager, next"
            :total="serviceList.total"
          >
          </el-pagination>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Enterprise",
  data() {
    return {
      page: 1,
      activeIndex: "1",
      serviceList: "",
    };
  },
  created() {
    this.getServiceList();
  },
  methods: {
    hanpages(v) {
      // console.log(0909,v);
      // console.log(1000, v);
      this.page = v;
    },
    async getServiceList() {
      const data = await this.$store.dispatch("market/getServiceList", {
        page: this.page,
        cate_id: 102,
      });
      console.log(data);
      this.serviceList = data.data;
    },
  },
  computed: {
    ...mapGetters({
      demoData: "demoData",
      userInfo: "userInfo",
    }),
  },
  watch: {
    page: {
      deep: true,
      handler: function (newV, oldV) {
        this.getServiceList();
      },
    },
  },
};
</script>

<style scoped>
.enterprise-describe {
  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;
}
.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 60px;
  background-color: black;
  color: white;
  height: 100%;
}

.question-bank-icon {
  font-size: 80px;
  color: rgb(236, 179, 238);
}

.circle-test {
  width: 5px;
  height: 5px;
  background-color: rgb(170, 163, 163);
  border-radius: 100%;
}

.three-icon {
  font-size: 40px;
  color: rgb(238, 170, 124);
}

.three-icon2 {
  font-size: 40px;
  color: rgb(71, 153, 207);
}

.three-icon3 {
  font-size: 40px;
  color: #11ba67;
}

.test-icon-three {
  width: 90%;
  margin-left: 5%;
}

.good-test {
  width: 90%;
  background-color: white;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: #11ba67;
  font-size: 16px;
  font-weight: 330;
  margin-top: 10px;
  border: #11ba67 1px solid;
}

.start-test {
  width: 90%;
  background-color: #11ba67;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 330;
  margin-top: 20px;
}

.ject-but:hover {
  transform: scale(1.1);
  color: rgb(248, 244, 244);
  background-color: #11ba67;
  border-radius: 2px;
}

.ject-but {
  border: none;
}
</style>
