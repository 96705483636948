module.exports = [
  {
    // id: "2",
    name: "demo",
    icon: "el-icon-edit",
    query: {},
  },
  {
    // id: "1",
    name: "test",
    icon: "fold",
    query: {},
  },
];
