module.exports = {
  //加密
  encrypt: {
    on: false,
    publicKey: "",
    iv: "",
    toBase64: false
  },
  //请求域名
  baseUrl: "https://www.gepardshop.com/",
  // baseUrl: process.env.BASE_URL + "api/",
  authorization: {
    // key: "Authorization",
    // prefix: "Bearer ",
    key: "token",
    prefix: ""
  },
  //响应成功code值
  responseSuccessCode: 0,
  //超时时间
  timeout: 20000
};