<template>
  <div>
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold text-gray-900">服务介绍</h1>
      </div>
    </header>
    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <section class="bg-white dark:bg-gray-900">
          <div class="container p-10 py-10 mx-auto">
            <div
              class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3"
            >
              <div
                v-for="(item, index) in serviceList.data"
                :key="index"
                class="p-8 space-y-3 border-2 border-blue-400 dark:border-blue-300 rounded-xl"
              >
                <h1
                  class="flex-x-center text-2xl font-semibold text-gray-700 capitalize dark:text-white"
                >
                  {{ item.title }}
                </h1>

                <p class="text-gray-500 text-xl dark:text-gray-300">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </section>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Enterprise",
  data() {
    return {
      page: 1,
      activeIndex: "1",
      serviceList: "",
    };
  },
  created() {
    this.getServiceList();
  },
  methods: {
    hanpages(v) {
      // console.log(0909,v);
      // console.log(1000, v);
      this.page = v;
    },
    async getServiceList() {
      const data = await this.$store.dispatch("market/getServiceList", {
        page: this.page,
        cate_id: 113,
      });
      console.log(data);
      this.serviceList = data.data;
    },
  },
  computed: {
    ...mapGetters({
      demoData: "demoData",
      userInfo: "userInfo",
    }),
  },
  watch: {
    page: {
      deep: true,
      handler: function (newV, oldV) {
        this.getServiceList();
      },
    },
  },
};
</script>

<style scoped>
.enterprise-describe {
  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;
}

.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 60px;
  background-color: black;
  color: white;
  height: 100%;
}

.question-bank-icon {
  font-size: 80px;
  color: rgb(236, 179, 238);
}

.circle-test {
  width: 5px;
  height: 5px;
  background-color: rgb(170, 163, 163);
  border-radius: 100%;
}

.three-icon {
  font-size: 40px;
  color: rgb(238, 170, 124);
}

.three-icon2 {
  font-size: 40px;
  color: rgb(71, 153, 207);
}

.three-icon3 {
  font-size: 40px;
  color: #11ba67;
}

.test-icon-three {
  width: 90%;
  margin-left: 5%;
}

.good-test {
  width: 90%;
  background-color: white;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: #11ba67;
  font-size: 16px;
  font-weight: 330;
  margin-top: 10px;
  border: #11ba67 1px solid;
}

.start-test {
  width: 90%;
  background-color: #11ba67;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 330;
  margin-top: 20px;
}

.ject-but:hover {
  transform: scale(1.1);
  color: rgb(248, 244, 244);
  background-color: #11ba67;
  border-radius: 2px;
}

.ject-but {
  border: none;
}
</style>
