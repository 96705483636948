<template>
  <div class="container flex mx-auto">
    <div class="w-1/4 mr-2 bg-white divide-y-2 mt-5">
      <div class="p-5">
        <Menu as="div" class="relative inline-block text-left">
          <div class="flex items-center">
            <p class="text-xl ml-2">人教版 · 七年级上册</p>

            <MenuButton
              class="
                inline-flex
                justify-center
                rounded-md
                border border-gray-300
                shadow-sm
                mx-3
                px-4
                py-2
                bg-white
                text-sm
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-100
                focus:ring-indigo-500
              "
            >
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="
                origin-top-right
                absolute
                right-0
                mt-2
                w-56
                rounded-md
                shadow-lg
                bg-white
                ring-1 ring-black ring-opacity-5
                divide-y divide-gray-100
                focus:outline-none
              "
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Edit</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Duplicate</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Archive</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Move</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Share</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Add to favorites</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Delete</a
                  >
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <div class="p-5">
        <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          :default-expanded-keys="[2, 3]"
          :default-checked-keys="[5]"
        >
        </el-tree>
      </div>
    </div>

    <div class="w-3/4 container bg-white mt-5 ml-2 p-4">
      <div class="flex">
        <div
          class="
            flex-1
            container
            px-3
            text-light
            font-bold
            border-l-4 border-green-400
          "
        >
          已选章节 <span class="font-thin">（0个）</span>
        </div>
        <span class="flex-end font-thin text-sm flex-y-center">
          <span class="iconfont icon-shanchu text-sm flex-y-center mx-1"></span>
          清空</span
        >
      </div>
      <div class="font-thin mt-4">您未选择章节！</div>
      <div class="splitLine"></div>
      <div
        class="
          flex-1
          container
          px-3
          text-light
          font-bold
          border-l-4 border-green-400
          mt-4
        "
      >
        试卷设置 ：
      </div>
      <div class="flex p-4">
        <div>出卷方式 ：</div>
        <div class="ml-5">
          <el-radio v-model="radio" label="1">关联出题</el-radio>
          <el-radio v-model="radio" label="2">综合性选题</el-radio>
        </div>
      </div>
      <div class="flex p-4">
        <div>试题过滤 ：</div>
        <div class="ml-5">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="过滤已用"></el-checkbox>
            <el-checkbox label="防超纲出题"></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="flex p-4">
        <div class="">试题年份 ：</div>
        <div class="ml-5">
          <el-checkbox-group v-model="checkList2">
            <el-checkbox label="2022"></el-checkbox>
            <el-checkbox label="2021"></el-checkbox>
            <el-checkbox label="2020"></el-checkbox>
            <el-checkbox label="2019"></el-checkbox>
            <el-checkbox label="2018"></el-checkbox>
            <el-checkbox label="更早"></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="splitLine"></div>
      <div
        class="
          flex-1
          container
          px-3
          text-light
          font-bold
          border-l-4 border-green-400
          mt-4
        "
      >
        题型/题量设置 ：
      </div>
      <div class="p-5">
        <el-checkbox-group v-model="checkList3">
          <el-checkbox label="单选题"></el-checkbox>
          <el-checkbox label="判断题"></el-checkbox>
          <el-checkbox label="填空题"></el-checkbox>
          <el-checkbox label="计算题"></el-checkbox>
          <el-checkbox label="解答题"></el-checkbox>
          <el-checkbox label="作图题"></el-checkbox>
          <el-checkbox label="综合题"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="border-sill m-5">
        <div class="p-4 title-border font-light">
          单选题：<span class="font-thin text-sm">难度系数（0）</span>
        </div>
        <div class="p-4 font-light">
          <span class="text-sm">容易</span>
          <button class="number-button mx-2">0</button>
          <span class="font-thin text-sm">0道试题可用</span>
        </div>
        <div class="p-4 font-light">
          <span class="text-sm">普通 </span>
          <button class="number-button mx-2">0</button>
          <span class="font-thin text-sm">0道试题可用</span>
        </div>
        <div class="p-4 font-light">
          <span class="text-sm">困难</span>
          <button class="number-button mx-2">0</button>
          <span class="font-thin text-sm">0道试题可用</span>
        </div>
      </div>
      <div class="flex-x-y-center">
        <div class="font-light">合计：<span class="text-red">0道</span></div>
        <div class="font-thin text-gray mx-2">｜</div>
        <div class="font-light">
          该份试卷总体难度系数：<span class="text-red">0</span>
        </div>
      </div>
      <div class="flex-x-y-center">
        <button class="generate-button">生成试卷</button>
      </div>
    </div>
    <div class="position">
      <div class="position-tip text-sm flex-x-y-center">
        <div>
          <div class="tip-bottom text-xs">
            <div class="flex-x-y-center">QQ</div>
            客服
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-women"></div>
            我们
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-xiugaioryijian"></div>
            建议
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-fanhuidingbu"></div>
            顶部
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
export default {
  name: "Zhangjie",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  data() {
    return {
      checkList: [],
      checkList2: [],
      checkList3: [],
      radio: "1",
      selectList: [
        {
          title: "题型",
          children: [
            { label: "全部", value: "quanbu" },
            { label: "单选", value: "danxuan" },
            { label: "判断", value: "panduan" },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "难度",
          children: [
            { label: "全部", value: "quanbu" },
            { label: "容易", value: "rongyi" },
            { label: "简单", value: "jd" },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "方式",
          children: [
            { label: "全部", value: "quanbu" },
            { label: "关联出题", value: "guanlainchuti" },
            { label: "精准出题", value: "jingzhunchuti" },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "更多",
          children: [
            { label: "全部", value: "quanbu" },
            { label: "题类", value: "tilei" },
            { label: "年级", value: "nianji" },
            { label: "地区", value: "diqu" },
            { label: "年份", value: "nianfen" },
            { label: "过滤已用", value: "guolvyiyong" },
            { label: "防超纲选题", value: "fangchaogangxuanti" },
          ],
          activeIndex: "quanbu",
        },
      ],
      activeIndex: "1",
      questionList: [
        {
          questiontype: "1",
        },
      ],
      data: [
        {
          id: 1,
          label: "智能组卷",
          children: [
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 2,
              label: "二级 2-2",
              disabled: true,
              children: [
                {
                  id: 6,
                  label: "三级 3-2-1",
                },
                {
                  id: 7,
                  label: "三级 3-2-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      isSelected: false,
      formModel: {
        user: "",
        password: "",
      },
      formRules: {
        user: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    itemTap(index, value) {
      this.selectList[index].activeIndex = value;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    async submit() {
      try {
        const isValid = await this.$refs.formRefs.validate();
        console.log("isValid", isValid);
        if (isValid) {
          this.$store.commit("user/setToken", "token");
          this.$router.replace({
            name: "home",
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
  font-size: 10px;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 35px;
  background-color: rgb(42, 41, 41);
  color: white;
  height: 100%;
  padding: 5px;
}
.generate-button:hover {
  cursor: pointer;
  // transform: scale(1.1);
}
.generate-button {
  background-color: rgb(20, 161, 20);
  width: 200px;
  height: 35px;
  border-radius: 4px;
  margin-top: 20px;
  color: white;
  margin-bottom: 20px;
}
.number-button {
  width: 60px;
  height: 25px;
  border: rgb(219, 213, 213) 1px solid;
  border-radius: 4px;
  color: rgb(90, 87, 87);
}
.title-border {
  background-color: rgb(248, 247, 247);
}
.border-sill {
  border: rgb(241, 237, 237) 1px solid;
  width: 240px;
  height: 230px;
}
.splitLine {
  background-color: rgb(241, 237, 237);
  height: 1px;
  width: 100%;
  margin-top: 40px;
}
.result-search {
  border: rgb(219, 215, 215) 1px solid;
  border-radius: 3px;
}
.zhangjie-clsss {
  color: red;
  font-size: 14px;
}
.list {
  margin-top: 10px;
  .list-child-btn-active {
    padding: 5px 10px;
    border-radius: 6px;
    background-color: #2bbb61;
    color: #ffffff;
    margin: 0 8px;
  }
  .list-child-btn-none {
    padding: 5px 10px;
    border-radius: 6px;
    color: #000000;
    margin: 0 8px;
  }
}
</style>
