<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <Popover class="relative bg-white shadow">
      <div class="bg-indigo-600">
        <div class="max-w-7xl mx-auto">
          <nav
            class="
              sticky
              top-0
              z-40
              flex
              text-sm
              font-medium
              items-center
              text-center text-white
              py-5
              ring-1 ring-gray-900 ring-opacity-5
              shadow-sm
            "
          >
            <div class="left-0 absolute ml-4">云豹框架题库云</div>
            <div
              class="
                max-w-container
                mx-auto
                right-0
                absolute
                flex
                space-x-5
                sm:space-x-10
                lg:space-x-14
              "
            >
              <a href="#product-marketing">登录</a>
              <a href="#product-application-ui">注册</a>
            </div>
          </nav>
        </div>
      </div>

      <div class="max-w-7xl mx-auto">
        <div class="py-5 flex justify-between items-center bg-white">
          <div class="font-semibold text-2xl ml-4">
            {{ $parent.siteInfo.site_info.title }}
          </div>
          <div class="w-1/2">
            <input
              type="text"
              name=""
              id=""
              placeholder="输入关键字"
              class="rounded-2xl bg-gray-100 py-3 px-5 w-full"
            />
          </div>
          <div
            class="
              p-2
              bg-green-500
              rounded-md
              text-white
              font-semibold
              flex
              items-center
              justify-center
            "
          >
            申请试用
          </div>
        </div>
        <div class="flex justify-between items-center py-6 md:justify-start">
          <div class="-mr-2 -my-2 md:hidden">
            <PopoverButton
              class="
                bg-white
                rounded-md
                p-2
                inline-flex
                items-center
                justify-center
                text-gray-400
                hover:text-gray-500 hover:bg-gray-100
                focus:outline-none
                focus:ring-2
                focus:ring-inset
                focus:ring-indigo-500
              "
            >
              <span class="sr-only">Open menu</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup
            as="nav"
            class="hidden flex-y-center md:flex space-x-10"
          >
            <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-white' : 'text-white',
                  'group w-70 bg-green-500 p-2 rounded-md inline-flex items-center text-base font-medium',
                ]"
              >
                <span>初中数学</span>
                <ChevronDownIcon
                  :class="[
                    open ? 'text-gray-600' : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  class="
                    absolute
                    z-10
                    -ml-4
                    mt-3
                    transform
                    px-2
                    max-w-md
                    sm:px-0
                    lg:ml-0 lg:left-1/2 lg:-translate-x-1/2
                  "
                >
                  <div
                    class="
                      rounded-lg
                      shadow-lg
                      ring-1 ring-black ring-opacity-5
                      overflow-hidden
                    "
                  >
                    <div class="relative grid gap-6 bg-white sm:gap-8">
                      <div class="container mx-auto bg-white p-3">
                        <template
                          v-for="(stage, index) in school_stage"
                          :key="index"
                        >
                          <div class="font-bold">{{ stage.title }}</div>
                          <button
                            class="
                              mx-2
                              my-1
                              p-1.5
                              font-extralight
                              text-sm
                              ject-but
                            "
                            v-for="(item, subject) in stage.subjects"
                            :key="subject"
                          >
                            {{ item }}
                          </button>
                        </template>
                      </div>
                    </div>
                    <div
                      class="
                        px-5
                        py-5
                        bg-gray-50
                        space-y-6
                        sm:flex sm:space-y-0 sm:space-x-10 sm:px-8
                      "
                    >
                      <div
                        v-for="item in callsToAction"
                        :key="item.name"
                        class="flow-root"
                      >
                        <a
                          :href="item.href"
                          class="
                            -m-3
                            p-3
                            flex
                            items-center
                            rounded-md
                            text-base
                            font-medium
                            text-gray-900
                            hover:bg-gray-100
                          "
                        >
                          <component
                            :is="item.icon"
                            class="flex-shrink-0 h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                          <span class="ml-3">{{ item.name }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
            <a
              href="#/tiku/main"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              首页
            </a>
            <a
              href="#/tiku/zhangjie"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              章节选题
            </a>
            <a
              href="#/tiku/zhishidian"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              知识点选题
            </a>
            <a
              href="#/tiku/shijuan"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              试卷库
            </a>
            <a
              href="#/tiku/zujuan"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              智能组卷
            </a>

            <a
              href="#/tiku/zhuanti"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              专题特供
            </a>
          </PopoverGroup>
        </div>
      </div>

      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="
            absolute
            top-0
            inset-x-0
            p-2
            transition
            transform
            origin-top-right
            md:hidden
          "
        >
          <div
            class="
              rounded-lg
              shadow-lg
              ring-1 ring-black ring-opacity-5
              bg-white
              divide-y-2 divide-gray-50
            "
          >
            <div class="pt-5 pb-6 px-5">
              <div class="flex items-center justify-between">
                <div>
                  <img
                    class="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                  />
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="
                      bg-white
                      rounded-md
                      p-2
                      inline-flex
                      items-center
                      justify-center
                      text-gray-400
                      hover:text-gray-500 hover:bg-gray-100
                      focus:outline-none
                      focus:ring-2
                      focus:ring-inset
                      focus:ring-indigo-500
                    "
                  >
                    <span class="sr-only">Close menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="mt-6">
                <nav class="grid gap-y-8">
                  <a
                    v-for="item in solutions"
                    :key="item.name"
                    :href="item.href"
                    class="
                      -m-3
                      p-3
                      flex
                      items-center
                      rounded-md
                      hover:bg-gray-50
                    "
                  >
                    <component
                      :is="item.icon"
                      class="flex-shrink-0 h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                    <span class="ml-3 text-base font-medium text-gray-900">
                      {{ item.name }}
                    </span>
                  </a>
                </nav>
              </div>
            </div>
            <div class="py-6 px-5 space-y-6">
              <div class="grid grid-cols-2 gap-y-4 gap-x-8">
                <a
                  href="#"
                  class="
                    text-base
                    font-medium
                    text-gray-900
                    hover:text-gray-700
                  "
                >
                  Pricing
                </a>

                <a
                  href="#"
                  class="
                    text-base
                    font-medium
                    text-gray-900
                    hover:text-gray-700
                  "
                >
                  Docs
                </a>
                <a
                  v-for="item in resources"
                  :key="item.name"
                  :href="item.href"
                  class="
                    text-base
                    font-medium
                    text-gray-900
                    hover:text-gray-700
                  "
                >
                  {{ item.name }}
                </a>
              </div>
              <div>
                <a
                  href="#"
                  class="
                    w-full
                    flex
                    items-center
                    justify-center
                    px-4
                    py-2
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-base
                    font-medium
                    text-white
                    bg-indigo-600
                    hover:bg-indigo-700
                  "
                >
                  注册
                </a>
                <p class="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?
                  {{ " " }}
                  <a href="#" class="text-indigo-600 hover:text-indigo-500">
                    登录
                  </a>
                </p>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
    <div class="min-h-full min-h-screen h-full w-full bg-gray-100">
      <div class="max-w-7xl mx-auto">
        <div class="flex flex-col py-5">
          <nav aria-label="Breadcrumb">
            <ol
              role="list"
              class="max-w-2xl mx-auto flex items-center space-x-2 lg:max-w-7xl"
            >
              <li v-for="breadcrumb in breadcrumbs" :key="breadcrumb.id">
                <div class="flex items-center">
                  <a
                    :href="breadcrumb.href"
                    class="mr-2 text-sm font-medium text-gray-900"
                  >
                    {{ breadcrumb.name }}
                  </a>
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    class="w-4 h-5 text-gray-300"
                  >
                    <path
                      d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z"
                    />
                  </svg>
                </div>
              </li>
              <li class="text-sm">
                <a
                  href=" "
                  aria-current="page"
                  class="font-medium text-gray-500 hover:text-gray-600"
                >
                  {{ this.$route.meta.title }}
                </a>
              </li>
            </ol>
          </nav>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";
const breadcrumbs = [
  {
    id: 1,
    name: "首页",
    href: "#",
  },
  {
    id: 2,
    name: "题库",
    href: "#",
  },
];
const school_stage = [
  {
    title: "小学",
    subjects: ["语文", "数学3", "英语", "科学", "政d治思品（道德与法治）"],
  },
  {
    title: "中学",
    subjects: ["语文", "数3学", "英语5", "科学", "政治思7品（道德与法治）"],
  },
  {
    title: "高中",
    subjects: ["语文", "数学", "英8语", "科学7", "政7治思品（道德与法治）"],
  },
];
const solutions = [
  {
    name: "Analytics",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: ChartBarIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: CursorClickIcon,
  },
  {
    name: "Security",
    description: "Your customers' data will be safe and secure.",
    href: "#",
    icon: ShieldCheckIcon,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: ViewGridIcon,
  },
  {
    name: "Automations",
    description:
      "Build strategic funnels that will drive your customers to convert",
    href: "#",
    icon: RefreshIcon,
  },
];
const callsToAction = [
  {
    name: "Watch Demo",
    href: "#",
    icon: PlayIcon,
  },
  {
    name: "Contact Sales",
    href: "#",
    icon: PhoneIcon,
  },
];
const resources = [
  {
    name: "Help Center",
    description:
      "Get all of your questions answered in our forums or contact support.",
    href: "#",
    icon: SupportIcon,
  },
  {
    name: "Guides",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    href: "#",
    icon: BookmarkAltIcon,
  },
  {
    name: "Events",
    description:
      "See what meet-ups and other events we might be planning near you.",
    href: "#",
    icon: CalendarIcon,
  },
  {
    name: "Security",
    description: "Understand how we take your privacy seriously.",
    href: "#",
    icon: ShieldCheckIcon,
  },
];
const recentPosts = [
  {
    id: 1,
    name: "Boost your conversion rate",
    href: "#",
  },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
  },
  {
    id: 3,
    name: "Improve your customer experience",
    href: "#",
  },
];

export default {
  data() {
    return {};
  },
  mounted() {},

  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {
      solutions,
      callsToAction,
      resources,
      recentPosts,
      breadcrumbs,
      school_stage,
    };
  },
};
</script>
