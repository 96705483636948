<template>
  <div>
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold text-gray-900">
          {{ apiInfo.title }}
        </h1>
        <div class="lg:flex lg:items-center lg:justify-between mt-5">
          <div class="flex-1 min-w-0">
            {{ apiInfo.description }}
          </div>
          <div v-if="userInfo.id" class="mt-5 flex lg:mt-0 lg:ml-4">
            <a
              href="#my/finance/sorder"
              class="p-2 bg-green-500 rounded-md text-white font-semibold flex items-center justify-center"
            >
              查看订单
            </a>
          </div>
        </div>
      </div>
    </header>
    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="lg:flex my-5">
          <div
            v-if="apiInfo.cate_id == 105"
            class="container bg-white mr-5 mb-10 lg:w-3/4 p-4"
          >
            <div class="bg-white mb-5 divide-y-2">
              <div class="p-4 text-xl">接口详情</div>
              <div class="p-4 text-gray-500" v-html="apiInfo.content"></div>
            </div>
            <div class="bg-white mb-5 divide-y-2">
              <div class="p-4 text-xl">接口费用</div>
              <div class="p-4 text-gray-500">
                单次 {{ apiInfo.price }}元/次，最低
                {{ apiInfo.discount_price }}元/次起
              </div>
            </div>
            <div class="bg-white mb-5 divide-y-2">
              <div class="p-4 text-xl">购买说明</div>
              <div class="p-4 text-gray-500">
                接口购买后可以立即使用，请至<strong>我的服务->我的API</strong>查看已购买接口和调用统计。如剩余次数不足，请及时再次购买。
              </div>
            </div>
            <div class="bg-white mb-5 divide-y-2">
              <div class="p-4 text-xl">API文档</div>
              <div class="p-4 text-gray-500">
                接口使用参考这里：https://www.gepardshop.com/mapi
              </div>
            </div>
          </div>
          <div v-else class="container bg-white mr-5 mb-10 lg:w-3/4 p-4">
            <article
              class="markdown-body p-4 text-gray-500"
              v-html="apiInfo.content"
            ></article>
          </div>

          <div class="container lg:w-1/4">
            <div class="bg-white mb-5 divide-y-2">
              <div class="p-4">购买服务</div>
              <div class="p-3 container mx-auto">
                <div class="container">
                  <div class="bg-white mb-5">
                    <div class="p-1">选择套餐版本</div>
                    <div class="px-4 text-gray-500">
                      <div
                        v-for="(item, index) in apiInfo.meal"
                        :key="index"
                        :class="[
                          item.id == meal.id
                            ? 'text-green-400 border-green-600'
                            : 'border-grey-600',
                          ' border-2 mb-3 p-2 point',
                        ]"
                        @click="
                          changeMeal(item.id, item.price, item.discount_price)
                        "
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                  <div class="bg-white mb-5">
                    <div class="p-1">价格</div>
                    <div class="px-4 text-gray-500">
                      <span class="line-through">¥{{ meal.price }}元 </span>
                      <span class="text-red">
                        ¥{{ meal.discount_price }}元</span
                      >
                    </div>
                  </div>
                  <div class="flex-x-center">
                    <div
                      @click="createOrder()"
                      class="point p-2 px-5 w-30 bg-red-500 rounded-md text-white font-semibold items-center justify-center"
                    >
                      立即购买
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white p-4">
              <div
                class="p-4 bg-white space-y-3 dark:border-blue-300 rounded-xl point"
              >
                <div class="flex-x-center">
                  <span class="inline-block text-blue-500 dark:text-blue-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-20 w-"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </div>

                <h1
                  class="text-xl mb-10 text-center font-semibold text-gray-700 capitalize dark:text-white"
                >
                  云豹框架官方接口
                </h1>

                <p class="pt-5 text-gray-500 dark:text-gray-300">
                  打造云豹框架生态接口服务，致力于统一的API接口调用服务，提供SDK支持
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
    <el-dialog
      title="是否已完成支付"
      v-model="showPayDialog"
      width="30%"
      center
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPayDialog = false">取消支付</el-button>
        <el-button type="primary" @click="checkOrderPay()"
          >已完成支付</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import "github-markdown-css/github-markdown.css";
export default {
  name: "Api",
  data() {
    return {
      showPayDialog: false,
      apiInfo: "",
      order: "",
      meal: {
        id: 0,
        price: 0,
        discount_price: 0,
      },
    };
  },
  created() {
    this.getServiceInfo();
  },
  computed: {
    ...mapGetters({
      demoData: "demoData",
      userInfo: "userInfo",
    }),
  },
  methods: {
    async checkOrderPay() {
      const res = await this.$store.dispatch("market/getOrderInfo", {
        order_sn: this.order.order_sn,
      });
      console.log("check:", res);
      if (res.code == 1 && res.data.is_pay == 1) {
        this.showPayDialog = false;
        this.$message({
          message:
            "购买成功！市场订单为手动发货，平台会尽快处理您的订单，请耐心等待！",
          type: "success",
        });
      }
    },
    changeMeal(id, price, discount_price) {
      this.meal.id = id;
      this.meal.price = price;
      this.meal.discount_price = discount_price;
    },
    async getServiceInfo() {
      const res = await this.$store.dispatch("market/getServiceInfo", {
        id: this.$route.query.id,
      });
      console.log(res);
      this.apiInfo = res.data;
      if (this.apiInfo.meal.length) {
        this.changeMeal(
          this.apiInfo.meal[0].id,
          this.apiInfo.meal[0].price,
          this.apiInfo.meal[0].discount_price
        );
      }
    },
    async createOrder() {
      const res = await this.$store.dispatch("market/createOrder", {
        meal_id: this.meal.id,
      });
      console.log(res);
      if (res.code == 1) {
        //toast('测试', { type: "warning" });

        this.order = res.data.order;
        console.log("order:", this.order);
        if (this.order.is_pay == 1) {
          this.$message({
            message:
              "购买成功！市场订单为手动发货，平台会尽快处理您的订单，请耐心等待！",
            type: "success",
          });
        } else {
          this.showPayDialog = true;
          window.open(res.data.pay_url);
        }
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 60px;
  background-color: black;
  color: white;
  height: 100%;
}

.question-bank-icon {
  font-size: 80px;
  color: rgb(236, 179, 238);
}

.circle-test {
  width: 5px;
  height: 5px;
  background-color: rgb(170, 163, 163);
  border-radius: 100%;
}

.three-icon {
  font-size: 40px;
  color: rgb(238, 170, 124);
}

.three-icon2 {
  font-size: 40px;
  color: rgb(71, 153, 207);
}

.three-icon3 {
  font-size: 40px;
  color: #11ba67;
}

.test-icon-three {
  width: 90%;
  margin-left: 5%;
}

.good-test {
  width: 90%;
  background-color: white;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: #11ba67;
  font-size: 16px;
  font-weight: 330;
  margin-top: 10px;
  border: #11ba67 1px solid;
}

.start-test {
  width: 90%;
  background-color: #11ba67;
  margin-left: 5%;
  height: 40px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 330;
  margin-top: 20px;
}

.ject-but:hover {
  transform: scale(1.1);
  color: rgb(248, 244, 244);
  background-color: #11ba67;
  border-radius: 2px;
}

.ject-but {
  border: none;
}
</style>
