<template>
  <div class="md:flex md:w-full h-full md:flex-col md:fixed">
    <div class="h-full overflow-hidden">
      <div class="h-full flex">
        <!-- Narrow sidebar -->
        <div class="hidden w-28 bg-indigo-700 overflow-y-auto md:block">
          <div class="w-full py-6 flex flex-col items-center">
            <div class="flex-shrink-0 flex items-center">
              <img
                class="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                alt="Workflow"
              />
            </div>
            <div class="flex-1 mt-6 w-full px-2 space-y-1">
              <a
                v-for="item in sidebarNavigation"
                :key="item.name"
                :href="item.href"
                :class="[
                  $route.href.includes(`${item.match}`)
                    ? 'bg-indigo-800 text-white'
                    : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                  'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium',
                ]"
                :aria-current="
                  $route.href.includes(`${item.match}`) ? 'page' : undefined
                "
              >
                <component
                  :is="item.icon"
                  :class="[
                    $route.href.includes(`${item.match}`)
                      ? 'text-white'
                      : 'text-indigo-300 group-hover:text-white',
                    'h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                <span class="mt-2">{{ item.name }}</span>
              </a>
            </div>
          </div>
        </div>

        <!-- Mobile menu -->
        <TransitionRoot as="template" :show="mobileMenuOpen">
          <Dialog as="div" class="md:hidden" @close="mobileMenuOpen = false">
            <div class="fixed inset-0 z-40 flex">
              <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay
                  class="fixed inset-0 bg-gray-600 bg-opacity-75"
                />
              </TransitionChild>
              <TransitionChild
                as="template"
                enter="transition ease-in-out duration-300 transform"
                enter-from="-translate-x-full"
                enter-to="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leave-from="translate-x-0"
                leave-to="-translate-x-full"
              >
                <div
                  class="relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col"
                >
                  <TransitionChild
                    as="template"
                    enter="ease-in-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in-out duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                  >
                    <div class="absolute top-1 right-0 -mr-14 p-1">
                      <button
                        type="button"
                        class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                        @click="mobileMenuOpen = false"
                      >
                        <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                        <span class="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </TransitionChild>
                  <div class="flex-shrink-0 px-4 flex items-center">
                    <img
                      class="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                      alt="Workflow"
                    />
                  </div>
                  <div class="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                    <nav class="h-full flex flex-col">
                      <div class="space-y-1">
                        <a
                          v-for="item in sidebarNavigation"
                          :key="item.name"
                          :href="item.href"
                          :class="[
                            $route.href.includes(`${item.match}`)
                              ? 'bg-indigo-800 text-white'
                              : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                            'group py-2 px-3 rounded-md flex items-center text-sm font-medium',
                          ]"
                          :aria-current="
                            $route.href.includes(`${item.match}`)
                              ? 'page'
                              : undefined
                          "
                        >
                          <component
                            :is="item.icon"
                            :class="[
                              $route.href.includes(`${item.match}`)
                                ? 'text-white'
                                : 'text-indigo-300 group-hover:text-white',
                              'mr-3 h-6 w-6',
                            ]"
                            aria-hidden="true"
                          />
                          <span>{{ item.name }}</span>
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 w-14" aria-hidden="true">
                <!-- Dummy element to force sidebar to shrink to fit close icon -->
              </div>
            </div>
          </Dialog>
        </TransitionRoot>

        <!-- Content area -->
        <div class="flex-1 flex flex-col overflow-hidden">
          <header class="w-full">
            <div
              class="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex"
            >
              <button
                type="button"
                class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                @click="mobileMenuOpen = true"
              >
                <span class="sr-only">Open sidebar</span>
                <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
              </button>
              <div class="flex-1 flex justify-between px-4 sm:px-6">
                <div class="flex-1 flex"></div>

                <div
                  class="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6"
                >
                  <nav
                    aria-label="Global"
                    class="flex space-x-10 border-r border-gray-400 pr-4"
                  >
                    <a href="#/market" class="text-sm font-medium text-gray-900"
                      >首页</a
                    >
                  </nav>
                  <!-- Profile dropdown -->
                  <Menu as="div" class="relative flex-shrink-0">
                    <div>
                      <div
                        class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span class="sr-only">Open user menu</span>
                        <a href="#/my/setting/base" class="text-black">{{
                          userInfo.nickname
                        }}</a>
                      </div>
                    </div>
                  </Menu>

                  <nav
                    aria-label="Global"
                    class="flex space-x-10 border-l border-gray-400 pl-4"
                  >
                    <a
                      href="#/market/logout"
                      class="text-sm font-medium text-gray-900"
                      >退出</a
                    >
                  </nav>
                </div>
              </div>
            </div>
          </header>

          <!-- Main content -->
          <div class="flex-1 relative z-0 flex overflow-hidden">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";
import {
  Dialog,
  DialogOverlay,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  CogIcon,
  CollectionIcon,
  HomeIcon,
  MenuAlt2Icon,
  PhotographIcon,
  PlusSmIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
  UsersIcon,
  FolderIcon,
  InboxIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";

const sidebarNavigation = [
  {
    name: "控制台",
    href: "#/my/panel",
    icon: HomeIcon,
    match: "#/my/panel",
    current: false,
  },
  {
    name: "API服务",
    href: "#/my/service/apilist",
    icon: ViewGridIcon,
    match: "#/my/service",
    current: false,
  },
  // { name: "SAAS服务", href: "#", icon: PhotographIcon, current: false },
  {
    name: "项目管理",
    href: "#/my/project/joined",
    icon: UserGroupIcon,
    match: "#/my/project",
    current: false,
  },
  {
    name: "财务中心",
    href: "#/my/finance/sorder",
    icon: CollectionIcon,
    match: "#/my/finance",
    current: false,
  },
  {
    name: "个人设置",
    href: "#/my/setting/base",
    icon: CogIcon,
    match: "#/my/setting",
    current: true,
  },
];

const userNavigation = [
  { name: "个人资料", href: "#/my/setting/base" },
  { name: "退出登录", href: "#/market/logout" },
];

export default {
  components: {
    Dialog,
    DialogOverlay,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    MenuAlt2Icon,
    PlusSmIcon,
    SearchIcon,
    XIcon,
    UsersIcon,
    FolderIcon,
    InboxIcon,
  },
  setup() {
    const mobileMenuOpen = ref(false);

    return {
      sidebarNavigation,
      userNavigation,
      mobileMenuOpen,
    };
  },
  computed: {
    ...mapGetters({
      demoData: "demoData",
      userInfo: "userInfo",
      categoryList: "categoryList",
    }),
    activeMenus: function () {
      return this.categoryList.filter(function (category) {
        return category.nav_switch; //返回isShow=true的项，添加到activeUsers数组
      });
    },
  },
};
</script>
