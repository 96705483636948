<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="m-10 bg-grey w-full min-h-full overflow-y-auto">
    <div v-if="api_list.total > 0">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        API接口使用统计
      </h3>

      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div
          v-for="item in api_list.data"
          :key="item.id"
          class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
        >
          <dt>
            <div class="absolute bg-green-500 rounded-md p-3">
              <component
                is="CodeIcon"
                class="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </div>
            <p class="ml-16 text-base font-medium text-gray-700 truncate">
              {{ item.service.title }}
            </p>
          </dt>
          <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
            套餐使用量：
            <p class="text-2xl font-semibold text-gray-900">
              {{ item.used_nums }}/{{ item.total_nums }}
            </p>

            <div
              class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6"
            >
              <div class="text-sm">
                <router-link
                  class="font-medium text-indigo-600 hover:text-indigo-500"
                  :to="{
                    path: '/market/product',
                    query: { id: item.service.id },
                  }"
                  >再次购买
                </router-link>
              </div>
            </div>
          </dd>
        </div>
      </dl>
    </div>
    <div v-if="project_list.total > 0" class="my-10">
      <h3 class="text-lg leading-6 font-medium text-gray-900">项目统计</h3>

      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div
          v-for="item in project_list.data"
          :key="item.id"
          class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
        >
          <dt>
            <div class="absolute bg-green-500 rounded-md p-3">
              <component
                is="CubeIcon"
                class="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </div>
            <p class="ml-16 text-base font-medium text-gray-700 truncate">
              {{ item.title }}
            </p>
          </dt>
          <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
            <p class="text-2xl font-semibold text-gray-900">
              {{ item.task_count }}个任务
            </p>

            <div
              class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6"
            >
              <div class="text-sm">
                <router-link
                  v-show="item.unpay_total > 0"
                  class="font-medium text-red-600 hover:text-red-500"
                  :to="{
                    path: '/my/project/joined',
                  }"
                  >{{ item.unpay_total }}元待支付
                </router-link>
                <router-link
                  class="font-medium text-indigo-600 hover:text-indigo-500"
                  :to="{
                    path: '/my/project/joined',
                  }"
                  >进入管理
                </router-link>
              </div>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/vue/solid";
import {
  CursorClickIcon,
  MailOpenIcon,
  UsersIcon,
  CodeIcon,
  CubeIcon,
} from "@heroicons/vue/outline";

export default {
  name: "panel",
  components: {
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    CodeIcon,
    CubeIcon,
  },
  data() {
    return {
      api_list: { data: [] },
      project_list: { data: [] },
    };
  },
  mounted() {
    this.getApiList();
    // this.getProjectList();
    console.log(this.api_list);
  },
  methods: {
    async getApiList() {
      const res = await this.$store.dispatch("market/getApiList", {
        limit: 20,
      });
      console.log(res.data);
      if (res.code == 1) {
        this.api_list = res.data;
      }
    },
    async getProjectList() {
      const res = await this.$store.dispatch("market/getProjectList", {
        limit: 20,
      });
      console.log(res.data);
      if (res.code == 1) {
        this.project_list = res.data;
      }
    },
  },
  setup() {
    return {};
  },
};
</script>
