<template>
  <div class="min-h-full">
    <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
      <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <div class="flex items-center">
                <a href="#/market/" class="text-xl ml-2 text-white"
                  >云豹框架企业服务</a
                >
              </div>
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <template v-for="item in categoryList">
                  <a
                    v-if="item.nav_switch"
                    :key="item.name"
                    :href="'#/market/' + item.name"
                    :class="[
                      '#/market/' + item.name == $route.href
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'px-3 py-2 rounded-md text-sm font-medium',
                    ]"
                    :aria-current="item.current ? 'page' : undefined"
                    >{{ item.title }}</a
                  >
                </template>
              </div>
            </div>
            <div class="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
              <div class="max-w-lg w-full lg:max-w-xs">
                <label for="search" class="sr-only">搜索</label>
                <div class="relative">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <SearchIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    name="search"
                    class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-700 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm"
                    placeholder="搜索..."
                    type="search"
                    @keyup.enter="goSearch"
                    v-model="searchVal"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <!-- Profile dropdown -->
              <Menu v-show="userInfo.id" as="div" class="ml-3 relative">
                <div>
                  <a
                    href="#/my/panel"
                    class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span class="sr-only">打开用户菜单</span>
                    <img
                      class="h-8 w-8 rounded-full"
                      :src="userInfo.avatar"
                      alt=""
                    />
                    <span class="text-white mx-2">{{ userInfo.username }}</span>
                  </a>
                </div>
              </Menu>
              <div v-show="!userInfo.id" class="ml-3 relative">
                <a href="#/market/login" class="text-white mx-2">登录</a>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <template v-for="item in categoryList">
            <DisclosureButton
              v-if="item.nav_switch"
              :key="item.name"
              as="a"
              :href="'#/market/' + item.name"
              :class="[
                '#/market/' + item.name == $route.href
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block px-3 py-2 rounded-md text-base font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.title }}</DisclosureButton
            >
          </template>
        </div>
        <div class="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="max-w-lg w-full lg:max-w-xs">
            <label for="search" class="sr-only">Search2</label>
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
              >
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-700 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm"
                placeholder="搜索..."
                type="search"
              />
            </div>
          </div>
        </div>
        <div v-show="userInfo.id" class="pt-4 pb-3 border-t border-gray-700">
          <a href="#/my/panel" class="flex items-center px-5">
            <div class="flex-shrink-0">
              <img
                class="h-10 w-10 rounded-full"
                :src="userInfo.avatar"
                alt=""
              />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">
                {{ userInfo.username }}
              </div>
            </div>
          </a>
        </div>
      </DisclosurePanel>
    </Disclosure>
    <div class="bg-gray-100 min-h-full min-h-screen h-full">
      <slot></slot>

      <footer
        class="w-full max-w-container mx-auto border-t py-10 text-center flex items-center justify-center text-sm text-gray-500"
      >
        <p>Copyright 2022 北京永青宇创科技有限公司</p>
        <p class="ml-3 border-l border-gray-200 pl-3"></p>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@/store";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { SearchIcon } from "@heroicons/vue/solid";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SearchIcon,
    BellIcon,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {};
  },
  data() {
    return {
      searchVal: "",
    };
  },
  methods: {
    goSearch() {
      this.$router.push("/market/search?keywords=" + this.searchVal);
    },
  },

  mounted() {
    console.log("categoryList", this.categoryList);

    this.$store.commit("demo/setDemoData", "token");
    // console.log(this.$store.state.market.categoryList);
    console.log("in get", this.userInfo);
  },
  computed: {
    ...mapGetters({
      demoData: "demoData",
      userInfo: "userInfo",
      categoryList: "categoryList",
    }),
    activeMenus: function () {
      return this.categoryList.filter(function (category) {
        return category.nav_switch; //返回isShow=true的项，添加到activeUsers数组
      });
    },
  },
};
</script>
