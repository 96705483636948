<template>
  <router-view v-slot="{ Component }">
    <via-fade-transform>
      <component :is="Component" />
    </via-fade-transform>
  </router-view>
</template>

<script>
	
import Layout from "@/views/my/components/Layout";
import ViaFadeTransform from "@/components/ViaFadeTransform";
export default {
  name: "Sms",
  components: { Layout, ViaFadeTransform },
  data() {
    return {
		siteInfo: {
			site_info:{
				title:''
			}
		},
	};
  },
  created() {
  	// this.getSiteInfo()
  },
  methods: {
    async getSiteInfo() {
      const data = await this.$store.dispatch("site/getSiteInfo");
	  console.log(data);
      this.siteInfo = data.data;
    },
  },
};
</script>

<style></style>
