<template>
  <div class="container flex mx-auto">
    <div class="w-1/4 mr-2 bg-white divide-y-2 mt-5">
      <div class="p-5">
        <Menu as="div" class="relative inline-block text-left">
          <div class="flex items-center">
            <p class="text-xl ml-2">人教版 · 七年级上册</p>

            <MenuButton
              class="
                inline-flex
                justify-center
                rounded-md
                border border-gray-300
                shadow-sm
                mx-3
                px-4
                py-2
                bg-white
                text-sm
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-100
                focus:ring-indigo-500
              "
            >
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="
                origin-top-right
                absolute
                right-0
                mt-2
                w-56
                rounded-md
                shadow-lg
                bg-white
                ring-1 ring-black ring-opacity-5
                divide-y divide-gray-100
                focus:outline-none
              "
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Edit</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Duplicate</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Archive</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Move</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Share</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Add to favorites</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Delete</a
                  >
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <!-- {{ chapterList }} -->
      <div class="p-5">
        <el-tree
          :data="chapterList"
          show-checkbox
          node-key="id"
          :default-expanded-keys="[2, 3]"
          :default-checked-keys="[5]"
        >
        </el-tree>
      </div>
    </div>
    <div class="w-3/4 p-5">
      <div class="flex bg-white">
        <div class="flex-1">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            active-text-color="green"
          >
            <el-menu-item class="font-light" index="1">试题</el-menu-item>
            <el-menu-item class="font-light" index="2">同步套题</el-menu-item>
          </el-menu>
        </div>

        <div class="flex-end font-light flex-y-center mx-4">
          <span class="iconfont icon-filesync text-gray mr-2"></span>
          同步备课资源
        </div>
      </div>
      <div class="bg-white container p-4">
        <div class="flex list" v-for="(item, index) in selectList" :key="index">
          <span class="flex-y-center min-w-12">{{ item.title }} :</span>
          <div
            v-for="childItem in item.children"
            :key="childItem.value"
            @click="itemTap(index, childItem.value)"
            :class="
              childItem.value == item.activeIndex
                ? 'list-child-btn-active'
                : 'list-child-btn-none'
            "
          >
            {{ childItem.label }}
          </div>
        </div>
      </div>
      <div class="container bg-white mt-4 flex-y-center h-12">
        <div class="flex-1 mx-4 min-w-100">
          <span class="font-light">最新上传</span>
          <span class="font-light ml-7">最多使用</span>
          <input
            class="result-search ml-7 pl-1"
            type="text"
            placeholder="从结果中搜索
          "
          />
        </div>
        <div class="flex-end mx-4">
          <span class="font-thin">共计512529道题</span>
          <span class="font-thin mx-1">｜</span>
          <span class="font-light text-green">+选择本页全部试题</span>
        </div>
      </div>

      <div
        v-for="(item, index) in questionList"
        class="container bg-white mt-4 p-4"
      >
        <div class="font-thin">
          <span>填空题</span>
          <span class="mx-1">｜</span>
          <span>普通</span>
          <span class="mx-1">｜</span>
          <span>组卷一次</span>
          <span class="mx-1">｜</span>
          <span class="font-light">引用</span>
        </div>
        <div class="h-20 mt-4">
          <p>{{ item.title }}</p>
          <div v-for="(xxitem, xxindex) in item.answer" class="opt">
            {{ xxitem.option }}-{{ xxitem.answer }}
          </div>
        </div>

        <div class="flex-end h-10 flex-y-center">
          <span class="font-light text-green text-sm mx-1">有奖纠错</span>
          <span class="font-light text-green text-sm mx-1">收藏</span>
          <span class="font-light text-green text-sm mx-1">下载</span>
          <span class="font-light text-green text-sm mx-1">查看解析</span>
          <button
            class="font-light bg-green text-white text-sm mx-1 rounded p-1"
          >
            +选题
          </button>
        </div>
      </div>

      <div class="flex-x-center mt-4">
        <el-pagination background layout="prev, pager, next" :total="10000">
        </el-pagination>
      </div>
    </div>
    <div class="position">
      <div class="position-tip text-sm flex-x-y-center">
        <div>
          <div class="tip-bottom text-xs">
            <div class="flex-x-y-center">QQ</div>
            客服
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-women"></div>
            我们
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-xiugaioryijian"></div>
            建议
          </div>
          <div class="mt-4 tip-bottom text-xs">
            <div class="flex-x-y-center iconfont icon-fanhuidingbu"></div>
            顶部
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
export default {
  name: "Zhangjie",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  data() {
    return {
      chapterList: [],
      questionList: "",
      selectList: [
        {
          title: "题型",
          children: [
            {
              label: "全部",
              value: "quanbu",
            },
            {
              label: "单选",
              value: "danxuan",
            },
            {
              label: "判断",
              value: "panduan",
            },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "难度",
          children: [
            {
              label: "全部",
              value: "quanbu",
            },
            {
              label: "容易",
              value: "rongyi",
            },
            {
              label: "简单",
              value: "jd",
            },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "方式",
          children: [
            {
              label: "全部",
              value: "quanbu",
            },
            {
              label: "关联出题",
              value: "guanlainchuti",
            },
            {
              label: "精准出题",
              value: "jingzhunchuti",
            },
          ],
          activeIndex: "quanbu",
        },
        {
          title: "更多",
          children: [
            {
              label: "全部",
              value: "quanbu",
            },
            {
              label: "题类",
              value: "tilei",
            },
            {
              label: "年级",
              value: "nianji",
            },
            {
              label: "地区",
              value: "diqu",
            },
            {
              label: "年份",
              value: "nianfen",
            },
            {
              label: "过滤已用",
              value: "guolvyiyong",
            },
            {
              label: "防超纲选题",
              value: "fangchaogangxuanti",
            },
          ],
          activeIndex: "quanbu",
        },
      ],
      activeIndex: "1",
      questionList: [
        {
          questiontype: "1",
        },
      ],
      data: [
        {
          id: 1,
          label: "章节选题",
          children: [
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 2,
              label: "二级 2-2",
              disabled: true,
              children: [
                {
                  id: 6,
                  label: "三级 3-2-1",
                },
                {
                  id: 7,
                  label: "三级 3-2-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
            {
              id: 3,
              label: "二级 2-1",
              children: [
                {
                  id: 4,
                  label: "三级 3-1-1",
                },
                {
                  id: 5,
                  label: "三级 3-1-2",
                  disabled: true,
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      isSelected: false,
      formModel: {
        user: "",
        password: "",
      },
      formRules: {
        user: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getQuestionList();
    this.getChapterList();
  },
  methods: {
    async getChapterList() {
      const data = await this.$store.dispatch("tiku/getChapterList");
      console.log("ChapterList", data);
      this.chapterList = [data.data];
    },

    async getQuestionList() {
      const data = await this.$store.dispatch("tiku/getQuestionList");
      console.log(data);
      this.questionList = data.data;
    },

    itemTap(index, value) {
      this.selectList[index].activeIndex = value;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    async submit() {
      try {
        const isValid = await this.$refs.formRefs.validate();
        console.log("isValid", isValid);
        if (isValid) {
          this.$store.commit("user/setToken", "token");
          this.$router.replace({
            name: "home",
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.tip-bottom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.tip-bottom {
  border: none;
  font-size: 10px;
}

.position-tip2 {
  position: fixed;
  right: 0px;
  top: 30px;
  width: 50px;
  background-color: black;
  color: white;
  height: 100%;
}

.position-tip {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 35px;
  background-color: rgb(42, 41, 41);
  color: white;
  height: 100%;
  padding: 5px;
}
.result-search {
  border: rgb(219, 215, 215) 1px solid;
  border-radius: 3px;
}

.zhangjie-clsss {
  color: red;
  font-size: 14px;
}

.list {
  margin-top: 10px;

  .list-child-btn-active {
    padding: 5px 10px;
    border-radius: 6px;
    background-color: #2bbb61;
    color: #ffffff;
    margin: 0 8px;
  }

  .list-child-btn-none {
    padding: 5px 10px;
    border-radius: 6px;
    color: #000000;
    margin: 0 8px;
  }
}
</style>
