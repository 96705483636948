<template>
  <layout>
    <router-view v-slot="{ Component }">
      <via-fade-transform>
        <component :is="Component" />
      </via-fade-transform>
    </router-view>
  </layout>
</template>

<script>
import Layout from "@/views/market/components/Layout";
import ViaFadeTransform from "@/components/ViaFadeTransform";
export default {
  name: "Market",
  components: { Layout, ViaFadeTransform },
  data() {
    return {
      siteInfo: {
        site_info: {
          title: "",
        },
        categoryList: {},
      },
    };
  },
  mounted() {
    // this.getSiteInfo();
    this.getCategoryList();
  },
  methods: {
    async getSiteInfo() {
      const data = await this.$store.dispatch("site/getSiteInfo");
      console.log(data);
      this.siteInfo = data.data;
    },
    async getCategoryList() {
      const data = await this.$store.dispatch("market/getCategoryList");
      console.log(data);
      this.categoryList = data;
    },
  },
};
</script>

<style></style>
