module.exports = {
    router: [
        { dictLabel: "首页", dictValue: "home" },
        { dictLabel: "实例", dictValue: "demo" },
        { dictLabel: "测试", dictValue: "test" },
        { dictLabel: "题库", dictValue: "tiku" },
        { dictLabel: "题库首页", dictValue: "main" },
        { dictLabel: "试卷库", dictValue: "shijuan" },
        { dictLabel: "章节选题", dictValue: "zhangjie" },
        { dictLabel: "知识点选题", dictValue: "zhishidian" },
        { dictLabel: "智能组卷", dictValue: "zujuan" },
        { dictLabel: "专题特供", dictValue: "zhuanti" },
        { dictLabel: "登录", dictValue: "login" },
        { dictLabel: "用法示例", dictValue: "yongfa" },
        { dictLabel: "用法示例", dictValue: "base" },
    ],
};