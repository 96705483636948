<template>
  <div class="h-full overflow-y-auto overflow-x-hidden p-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MainContent",
};
</script>

<style></style>
